import { AfterViewInit, ChangeDetectorRef, Directive, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { Focusable } from '@nexuzhealth/shared/ui-toolkit/focus';
import { typeahead } from '@nexuzhealth/shared/util';
import { NgSelectComponent } from '@ng-select/ng-select';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

/**
 * Utility class to create "SearchComponents". When implementing a SearchComponent, one has to:
 * - extend this class
 * - provide the necessary markup, check the PatientSearchComponent for a simple example.
 * - implement the autocomplete() method
 * - optionally provide a placeholder through implementing the getPlaceholder() method.
 *
 * SearchComponents that want to "enrich" the items returned by the autocomplete() method, can wrap each item in a
 * "SearchOption", through additionaly implementing the SearchOptionMapper interface.
 */
@Directive()
// eslint-disable-next-line
export abstract class BaseSearchComponent<T>
  implements OnInit, AfterViewInit, ControlValueAccessor, Focusable, OnDestroy
{
  term$ = new Subject<string>();
  loading$ = new BehaviorSubject<boolean>(false);
  searchResult$: Observable<any[]>;
  @ViewChild(NgSelectComponent, { static: true }) selectComponent: NgSelectComponent;
  @Input() appendTo = null;
  @Input() multiple = false;
  protected destroy$ = new Subject<void>();
  private cachedValue;
  private afterviewInit = false;
  protected onChange: (option: T | T[] | SearchOption<T> | SearchOption<T>[]) => void;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.searchResult$ = this.term$.pipe(
      typeahead((term) => this.autocomplete.call(this, term), this.loading$),
      map((items: T[]) => items.map((item) => this._createOption(item)))
    );
  }

  ngAfterViewInit(): void {
    this.afterviewInit = true;
    if (this.cachedValue) {
      this.selectComponent.writeValue(this.cachedValue);
      this.cdr.detectChanges();
    }
  }

  registerOnChange(fn): void {
    this.onChange = (option: T | T[] | SearchOption<T> | SearchOption<T>[]) => {
      if (!isSearchOptionMapper(this)) {
        return fn(option);
      }
      if (Array.isArray(option)) {
        return fn(option.map((op) => op.value));
      }
      return fn(option?.value ?? null);
    };
    this.selectComponent.registerOnChange(this.onChange);
  }

  registerOnTouched(fn): void {
    this.selectComponent.registerOnTouched(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    this.selectComponent.setDisabledState(isDisabled);
  }

  writeValue(value: T | T[]): void {
    if (this.afterviewInit && this.selectComponent) {
      if (this.multiple) {
        const options = value ? (value as T[]).map((v) => this._createOption(v)) : [];
        this.selectComponent.writeValue(options);
      } else {
        const option = value ? this._createOption(value as T) : null;
        this.selectComponent.writeValue(option);
      }
    } else if (value) {
      if (this.multiple) {
        this.cachedValue = value ? (value as T[]).map((v) => this._createOption(v)) : [];
      } else {
        this.cachedValue = this._createOption(value as T);
      }
    }
  }

  /**
   * Provides an optional placeholder.
   */
  getPlaceholder() {
    return '';
  }

  setFocus() {
    this.selectComponent.focus();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Performs the actual search
   */
  protected abstract autocomplete(term): Observable<T[]>;

  private _createOption(item: T) {
    return isSearchOptionMapper(this) ? this.createOption(item) : item;
  }
}

import { map } from 'rxjs/operators';

export type SearchOption<T> = any & { value: T };

export interface SearchOptionMapper<T> {
  createOption: (t: T) => SearchOption<T>;
  getCompareOptions: () => (left: SearchOption<T>, right: SearchOption<T>) => boolean;
  trackOption?: (option: SearchOption<T>) => string;
}

function isSearchOptionMapper<T>(mapper: any): mapper is SearchOptionMapper<T> {
  return (mapper as SearchOptionMapper<T>).createOption !== undefined;
}
