import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NxhFormsModule } from '@nexuzhealth/shared/ui-toolkit/forms';
import { SharedUtilI18nModule } from '@nexuzhealth/shared/util';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { SelectsModule } from '@nexuzhealth/shared/ui-toolkit/selects';
import { DatePickerModule } from '@nexuzhealth/shared/ui-toolkit/date-picker';
import { AddRemoveButtonsModule } from '@nexuzhealth/shared/ui-toolkit/add-remove-buttons';
import { TimePickerInputModule } from '@nexuzhealth/shared/ui-toolkit/time-picker-input';
import { ToggleModule } from '@nexuzhealth/shared/ui-toolkit/toggle';
import { SharedTechFeatureE2eModule } from '@nexuzhealth/shared/tech/feature-e2e';
import { FormatClockTimePipeModule } from '@nexuzhealth/shared/ui-toolkit/pipes/format-clock-time';
import { ValidationModule } from '@nexuzhealth/shared/ui-toolkit/validation';
import { FirstCharsPipeModule } from '@nexuzhealth/shared/ui-toolkit/pipes/first-chars';
import { NumberFieldModule } from '@nexuzhealth/shared/ui-toolkit/number-field';
import { DaysSelectorComponent } from './days-selector/days-selector.component';
import { KeysPipe } from './keys.pipe';
import { TimePeriodComponent } from './time-period/time-period.component';
import { TimeClockComponent } from './time-clock/time-clock.component';
import { RecurrenceTabDayComponent } from './recurrence-tab-day/recurrence-tab-day.component';
import { RecurrenceTabMonthComponent } from './recurrence-tab-month/recurrence-tab-month.component';
import { RecurrencePeriodComponent } from './recurrence-period/recurrence-period.component';
import { RecurrenceTabWeekComponent } from './recurrence-tab-week/recurrence-tab-week.component';
import { RecurrencesComponent } from './recurrences/recurrences.component';
import { TimePeriodsComponent } from './time-periods/time-periods.component';
import { RecurrenceTabManualComponent } from './recurrence-tab-manual/recurrence-tab-manual.component';
import { RecurrenceToStringPipe } from './recurrence-to-string.pipe';
import { DaypartsComponent } from './dayparts/dayparts.component';
import { RotationRecurrenceToStringPipe } from './rotation-recurrence-to-string.pipe';

@NgModule({
  imports: [
    CommonModule,
    NxhFormsModule,
    SharedUtilI18nModule,
    SelectsModule,
    NgbTooltipModule,
    DatePickerModule,
    AddRemoveButtonsModule,
    TimePickerInputModule,
    ToggleModule,
    SharedTechFeatureE2eModule,
    FormatClockTimePipeModule,
    ValidationModule,
    FirstCharsPipeModule,
    NumberFieldModule,
  ],
  declarations: [
    DaysSelectorComponent,
    KeysPipe,
    RecurrencePeriodComponent,
    RecurrenceTabDayComponent,
    RecurrenceTabManualComponent,
    RecurrenceTabMonthComponent,
    RecurrenceTabWeekComponent,
    RecurrencesComponent,
    TimePeriodComponent,
    TimePeriodsComponent,
    TimeClockComponent,
    RecurrenceToStringPipe,
    DaypartsComponent,
    RotationRecurrenceToStringPipe,
  ],
  exports: [
    RecurrencesComponent,
    DaysSelectorComponent,
    KeysPipe,
    RecurrenceToStringPipe,
    RotationRecurrenceToStringPipe,
    TimeClockComponent,
  ],
})
export class RecurrenceModule {}
