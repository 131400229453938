import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ScrollspyService } from '@nexuzhealth/shared/ui-toolkit/scrollspy';
import { BehaviorSubject } from 'rxjs';

@Component({
  templateUrl: './input-list-demo.component.html',
  styleUrls: ['./input-list-demo.component.scss'],
})
export class InputListDemoComponent implements OnInit {
  form = new UntypedFormGroup({
    inputList: new UntypedFormArray([]),
    inputGroupList: new UntypedFormArray([]),
  });
  selected$ = new BehaviorSubject('inputList');

  template = `
  <div
    class="input-list"
    formArrayName="inputList"
    *ngFor="let input of inputList.controls; let i = index; let last = last"
  >
    <nh-control>
      <input [formControlName]="i" />
    </nh-control>
    <nxh-add-remove-buttons
      [showAdd]="last"
      (add)="addInput()"
      (remove)="removeInput(i)"
    ></nxh-add-remove-buttons>
  </div>
  `;

  code = `
  addInput() {
    this.inputList.push(new FormControl());
  }

  removeInput(index){
    this.inputList.removeAt(index);
  }
  `;

  templateGroup = `
  <div class="input-list" formArrayName="inputGroupList"
  *ngFor="let input of inputGroupList.controls; let i = index ; let last = last">
    <nxh-listgroup-form [formGroup]="input" class="list-item"></nxh-listgroup-form>
    <nxh-add-remove-buttons [showAdd]="last" (add)="addInputGroup()"
        (remove)="removeInputGroup(i)"></nxh-add-remove-buttons>
  </div>
  `;

  codeGroup = `
  addInputGroup() {
    this.inputGroupList.push(new FormGroup({
      name: new FormControl(),
      lastName: new FormControl()
    }));
  }

  removeInputGroup(index){
    this.inputGroupList.removeAt(index);
    if(index === 0) {
      this.addInputGroup();
    }
  }
  `;
  constructor(private scrollspyService: ScrollspyService) {}

  ngOnInit(): void {
    this.addInput();
    this.addInputGroup();
  }

  get inputList() {
    return this.form.get('inputList') as UntypedFormArray;
  }

  get inputGroupList() {
    return this.form.get('inputGroupList') as UntypedFormArray;
  }

  addInput() {
    this.inputList.push(new UntypedFormControl());
  }

  addInputGroup() {
    this.inputGroupList.push(
      new UntypedFormGroup({
        name: new UntypedFormControl(),
        lastName: new UntypedFormControl(),
      })
    );
  }

  removeInput(index) {
    this.inputList.removeAt(index);
  }

  removeInputGroup(index) {
    this.inputGroupList.removeAt(index);
    if (index === 0) {
      this.addInputGroup();
    }
  }

  scrollTo(id) {
    this.selected$.next(id);
    console.log('scrolltime');
    this.scrollspyService.scrollTo(id, { emitEvent: false });
  }

  scrolledInView(id) {
    this.selected$.next(id);
  }
}
