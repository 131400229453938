import { Component, Input } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { TimeslotType } from '@nexuzhealth/shared/domain';
import { RecurrencesFormService } from '../recurrences-form.service';

@Component({
  selector: 'nxh-recurrence-tab-manual',
  templateUrl: './recurrence-tab-manual.component.html',
  styleUrls: ['./recurrence-tab-manual.component.scss'],
})
export class RecurrenceTabManualComponent {
  @Input() parent!: UntypedFormArray;

  constructor(private recurrencesFormService: RecurrencesFormService) {}

  get validateDayPartOrTime() {
    return this.recurrencesFormService.getValidateDayPartOrTime();
  }

  get timings() {
    return this.parent.controls as UntypedFormGroup[];
  }

  get useTimeslots() {
    return this.recurrencesFormService.getUseTimeslots();
  }

  get useDayPartAllDay() {
    return this.recurrencesFormService.getUseDayPartAllDay();
  }

  get dayparts() {
    return this.recurrencesFormService.getDayParts();
  }

  addTiming() {
    this.parent.push(this.recurrencesFormService.createTimingControl());
  }

  removeTiming(index: number) {
    this.parent.removeAt(index);
    if (this.parent.length === 0) {
      this.addTiming();
    }
  }

  showEndDate() {
    return this.recurrencesFormService.getTimeSlotType() === TimeslotType.RANGE;
  }
}
