import { Injectable } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  DAY,
  DayPartOption,
  DayRecurrence,
  isRecurrenceTimingAllDay,
  isRecurrenceTimingParts,
  isRecurrenceTimingTimeslot,
  MonthDayRecurrence,
  MonthRecurrence,
  Period,
  RecurrenceTiming,
  RecurrenceTimingDayParts,
  SelectionType,
  Timeslot,
  TimeslotType,
  Timing,
  TimingType,
  toLocalClockTime,
  WeekRecurrence,
} from '@nexuzhealth/shared/domain';
import { endClockTimeAfterStartClockTimeValidator } from '@nexuzhealth/shared/util';
import { isBefore, isSameDay } from 'date-fns';
import { groupBy } from 'lodash-es';

@Injectable()
export class RecurrencesFormService {
  // These values are overwritten by default values from RecurrencesComponent
  private useTimeslots!: boolean;
  private useDayparts = false;
  private useDayPartAllDay!: boolean;
  private weekdaysOptional!: boolean;
  private periodsOfApplication!: Period[];
  private timeslotType!: TimeslotType;
  private useSameTimingsForAllDays!: boolean;
  private dayparts: DayPartOption[];
  private validateDayPartOrTime!: boolean;

  setValidateDayPartOrTime(validateDayPartOrTime: boolean) {
    this.validateDayPartOrTime = validateDayPartOrTime;
  }

  getValidateDayPartOrTime() {
    return this.validateDayPartOrTime;
  }

  setDayParts(dayparts: DayPartOption[]) {
    this.dayparts = dayparts;
  }

  getDayParts() {
    return this.dayparts;
  }

  setUseTimeslots(useTimeslots = true) {
    this.useTimeslots = useTimeslots;
  }

  setUseDayPartAllDay(useDayPartAllDay = false) {
    this.useDayPartAllDay = useDayPartAllDay;
  }

  getUseDayPartAllDay() {
    return this.useDayPartAllDay;
  }

  setUseDayparts(useDayparts: boolean) {
    this.useDayparts = useDayparts;
  }

  getUseTimeslots() {
    return this.useTimeslots;
  }

  getUseDayparts() {
    return this.useDayparts;
  }

  setWeekdaysOptional(weekdaysOptional: boolean) {
    this.weekdaysOptional = weekdaysOptional;
  }

  getWeekdaysOptional() {
    return this.weekdaysOptional;
  }

  setTimeSlotType(type: TimeslotType) {
    this.timeslotType = type;
  }

  getTimeSlotType() {
    return this.timeslotType;
  }

  setUseSameTimingsForAllDays(value: boolean) {
    this.useSameTimingsForAllDays = value;
  }

  getUseSameTimingsForAllDays() {
    return this.useSameTimingsForAllDays;
  }

  updatePeriodsOfApplication(periodsOfApplication: Period[], timings: UntypedFormArray) {
    this.setPeriodsOfApplication(periodsOfApplication);
    timings.controls.forEach((control) => {
      const date: AbstractControl | null = control.get('date');
      if (date?.touched) {
        date.updateValueAndValidity();
      }
    });
  }

  setPeriodsOfApplication(periodsOfApplication: Period[]) {
    this.periodsOfApplication = periodsOfApplication;
  }

  createDayRecurrenceGroup(dayRecurrence?: Readonly<DayRecurrence>) {
    const formGroup = new UntypedFormGroup({
      period: new UntypedFormControl(dayRecurrence?.period ?? 1, [Validators.required, Validators.min(1)]),
      day: new UntypedFormGroup({}),
    });

    const dayGroup = formGroup.get('day') as UntypedFormGroup;

    if (this.useTimeslots) {
      const timingsFormArray = new UntypedFormArray(
        dayRecurrence?.day?.timings && dayRecurrence?.day?.timings?.length > 0 ? [] : [this.createTimeslotControl()],
        { validators: Validators.minLength(1) }
      );
      dayGroup.addControl('timings', timingsFormArray);

      if (dayRecurrence) {
        dayRecurrence.day?.timings?.forEach((timing) => {
          if (isRecurrenceTimingTimeslot(timing)) {
            timingsFormArray.push(this.createTimeslotControl(timing.timeslot));
          }
        });
      }
    }

    // useDayparts is only true when the day parts are fully loaded
    if (this.useDayparts) {
      this.addDayparts(dayGroup, dayRecurrence?.day?.timings, dayRecurrence?.time);
    }

    return formGroup;
  }

  createWeekRecurrenceGroup(weekRecurrence?: WeekRecurrence) {
    const selector =
      (weekRecurrence?.week?.days && weekRecurrence.week.days.length > 0) || !this.weekdaysOptional
        ? 'weekday'
        : 'frequency';

    const frequencyDisabled = selector !== 'frequency';
    const daysDisabled = selector !== 'weekday';

    const weekFormGroup = new UntypedFormGroup(
      {
        _selectFrequencyOrWeekday: new UntypedFormControl(selector),
        frequency: new UntypedFormControl(
          {
            value: weekRecurrence?.week?.frequency === 0 ? 1 : weekRecurrence?.week?.frequency ?? 1,
            disabled: frequencyDisabled,
          },
          { validators: !frequencyDisabled ? [this.minOncePerWeek, Validators.min(1), Validators.max(7)] : [] }
        ),
        days: new UntypedFormArray([]),
      },
      { validators: [this.minOneDay] }
    );

    let sameTimingsForAllDays = true;
    if (this.useDayparts) {
      if (weekRecurrence) {
        // Are there no timings for specific days?
        sameTimingsForAllDays = !weekRecurrence?.week?.days.some((day) => day.timings.length > 0);
      }
      weekFormGroup.addControl('sameTimingsForAllDays', new UntypedFormControl(sameTimingsForAllDays));
      if (sameTimingsForAllDays) {
        this.addDayparts(
          weekFormGroup,
          weekRecurrence?.timings?.length > 0 ? weekRecurrence.timings : undefined,
          weekRecurrence?.time
        );
      }
    }

    const formGroup = new UntypedFormGroup({
      period: new UntypedFormControl(weekRecurrence?.period ?? 1, [Validators.required, Validators.min(1)]),
      week: weekFormGroup,
    });

    if (weekRecurrence) {
      weekRecurrence.week?.days?.forEach((day) => {
        this.addDayToWeek(
          formGroup.get('week') as UntypedFormGroup,
          day.weekday,
          {
            addTimeslot: this.useTimeslots,
            addDayparts: this.useDayparts,
            sameTimings: sameTimingsForAllDays,
          },
          day.timings,
          day.time
        );
      });
    }

    if (daysDisabled) {
      (formGroup.get('week')?.get('days') as UntypedFormArray).disable({ emitEvent: false });
    }

    return formGroup;
  }

  addDayToWeek(
    weekForm: UntypedFormGroup,
    day: DAY,
    { addTimeslot, addDayparts, sameTimings }: { addTimeslot: boolean; addDayparts: boolean; sameTimings: boolean },
    timings?: RecurrenceTiming[],
    time?: string
  ) {
    const daysArray = weekForm.get('days') as UntypedFormArray;
    const formGroup = new UntypedFormGroup({
      weekday: new UntypedFormControl(day),
    });

    if (addTimeslot) {
      let timeslotControls =
        timings
          ?.map((timing) => {
            return isRecurrenceTimingTimeslot(timing) ? this.createTimeslotControl(timing.timeslot) : null;
          })
          .filter((timing) => timing !== null) ?? [];
      if (timeslotControls.length === 0) {
        timeslotControls = [this.createTimeslotControl()];
      }

      const timingsFormArray = new UntypedFormArray(timeslotControls);
      if (this.useSameTimingsForAllDays && !weekForm.contains('timings')) {
        weekForm.addControl('timings', timingsFormArray);
      } else if (!this.useSameTimingsForAllDays) {
        formGroup.addControl('timings', timingsFormArray);
      }
    }

    if (addDayparts && !sameTimings) {
      this.addDayparts(formGroup, timings?.length > 0 ? timings : undefined, time);
    }

    daysArray.push(formGroup);

    const days = Object.values(DAY);
    daysArray.controls.sort((left, right) => {
      return days.indexOf(left.value.weekday) - days.indexOf(right.value.weekday);
    });

    return formGroup;
  }

  addDayparts(formGroup: UntypedFormGroup, timings: RecurrenceTiming[] = [], time?: string) {
    const parts = timings
      .filter((timing) => isRecurrenceTimingParts(timing))
      .map((timing) => {
        return (<RecurrenceTimingDayParts>timing).dayPartName;
      });

    const validateDayparts: ValidatorFn = (group: UntypedFormGroup) => {
      const valid = group.get('allDayOrTime').value === true || group.get('parts').value?.length > 0;
      return valid ? null : { '_errors.day-parts-incomplete': true };
    };

    const allDay = timings.find((timing) => isRecurrenceTimingAllDay(timing));
    const dayparts = new UntypedFormGroup(
      {
        parts: new UntypedFormControl(parts),
        allDayOrTime: new UntypedFormControl(!!allDay || !!time),
      },
      this.validateDayPartOrTime ? validateDayparts : null
    );

    if (!this.useDayPartAllDay) {
      dayparts.addControl(
        'time',
        new UntypedFormControl(time, time && this.validateDayPartOrTime ? Validators.required : null)
      );
    }

    formGroup.addControl('dayparts', dayparts);
  }

  createMonthRecurrenceGroup(monthRecurrence?: MonthRecurrence) {
    // collect default values
    const monthDayRecurrence: MonthDayRecurrence | undefined = monthRecurrence?.month.days[0];
    // When empty, default selection is DAYS
    const isNthDay = monthRecurrence ? monthRecurrence?.month.selectionType === SelectionType.DAYS : SelectionType.DAYS;
    const isWeekday = monthRecurrence?.month.selectionType === SelectionType.WEEKDAY;
    const isSelectedDays = monthRecurrence?.month.selectionType === SelectionType.SELECTED_DAYS;
    const nthDayPeriod = isNthDay ? monthRecurrence?.period ?? 1 : 1;
    const nthDayNthDay = isNthDay ? monthDayRecurrence?.nthDay ?? 1 : 1;
    const weekdayPeriod = isWeekday ? monthRecurrence?.period ?? 1 : 1;
    const weekdayWeekday = isWeekday ? monthDayRecurrence?.weekday?.weekday ?? DAY.MON : DAY.MON;
    const weekdayNth = isWeekday ? monthDayRecurrence?.weekday?.nth ?? 1 : 1;
    const selectedDays = isSelectedDays ? monthRecurrence?.month?.days?.map((day) => String(day.nthDay)) ?? [] : [];

    const formGroup = new UntypedFormGroup({
      period: new UntypedFormControl(monthRecurrence?.period ?? 1, [Validators.required, Validators.min(1)]),
      month: new UntypedFormGroup({
        days: new UntypedFormArray([]),
      }),
    });

    const dayFormGroup = new UntypedFormGroup({
      _selectNthDayOrWeekday: new UntypedFormControl(this.getSelectionTypeControlName(monthRecurrence)),
      nthDay: new UntypedFormGroup({
        _period: new UntypedFormControl({ value: nthDayPeriod, disabled: !isNthDay }, [
          Validators.required,
          Validators.min(1),
        ]),
        nthDay: new UntypedFormControl({ value: nthDayNthDay, disabled: !isNthDay }, [
          Validators.required,
          Validators.min(1),
        ]),
      }),
      weekday: new UntypedFormGroup({
        _period: new UntypedFormControl({ value: weekdayPeriod, disabled: !isWeekday }, [
          Validators.required,
          Validators.min(1),
        ]),
        weekday: new UntypedFormControl({ value: weekdayWeekday, disabled: !isWeekday }),
        nth: new UntypedFormControl({ value: weekdayNth, disabled: !isWeekday }, Validators.required),
      }),
      dayparts: new UntypedFormGroup({
        _period: new UntypedFormControl({ value: 1, disabled: !isSelectedDays }),
        days: new UntypedFormControl({ value: selectedDays, disabled: !isSelectedDays }),
      }),
    });

    if (this.useTimeslots) {
      const timingsFormArray = new UntypedFormArray(
        monthDayRecurrence && monthDayRecurrence.timings?.length > 0
          ? []
          : // dayparts is managed at higher level
            [this.createTimeslotControl()],
        { validators: Validators.minLength(1) }
      );

      monthDayRecurrence?.timings.forEach((timing) => {
        if (isRecurrenceTimingTimeslot(timing)) {
          timingsFormArray.push(this.createTimeslotControl(timing.timeslot));
        }
      });

      dayFormGroup.addControl('timings', timingsFormArray);
    }

    const daysFormArray = formGroup.get('month')?.get('days') as UntypedFormArray;
    daysFormArray.push(dayFormGroup);

    if (this.useDayparts) {
      this.addDayparts(
        formGroup.get('month') as UntypedFormGroup,
        monthRecurrence?.timings?.length > 0 ? monthRecurrence.timings : undefined,
        monthRecurrence?.time
      );
    }

    return formGroup;
  }

  createTimeslotControl(timeslot?: Timeslot) {
    return this.timeslotType === TimeslotType.RANGE
      ? new UntypedFormGroup({
          timeslot: new UntypedFormGroup(
            {
              start: new UntypedFormControl(timeslot?.start ?? null, Validators.required),
              end: new UntypedFormControl(timeslot?.end ?? null, Validators.required),
            },
            {
              validators: [endClockTimeAfterStartClockTimeValidator('start', 'end')],
            }
          ),
        })
      : new UntypedFormGroup({
          timeslot: new UntypedFormGroup({
            start: new UntypedFormControl(timeslot?.start ?? null, Validators.required),
          }),
        });
  }

  addTimings(timings: UntypedFormArray, existing?: UntypedFormGroup[]) {
    existing?.forEach((e) => timings.push(e, { emitEvent: false }));
  }

  addManual(parent: UntypedFormArray, timings: Timing[] = []) {
    const timingsByDateTime = groupBy(timings, 'dateTime');
    Object.entries(timingsByDateTime).forEach(([, filteredTimings]) => {
      parent.push(this.createTimingControl(filteredTimings), { emitEvent: false });
    });
  }

  createTimingControl(timings?: Timing[]) {
    // Length of timings will only be greater than 1 if the type is DAY_PART
    // The only difference between the timings in this function is the dayPart of the timing
    let timing: Timing;
    if (timings?.length > 0) {
      timing = timings[0];
    }
    const formGroup = new UntypedFormGroup({
      date: new UntypedFormControl(timing?.dateTime, [
        Validators.required,
        (c: AbstractControl) => this.dateBetweenPeriodsOfApplicationValidator(c),
      ]),
    });

    if (this.useTimeslots) {
      const startValue = timing?.dateTime ? toLocalClockTime(new Date(timing.dateTime)) : null;
      const endValue =
        timing?.type === TimingType.RANGE && timing.endDateTime ? toLocalClockTime(new Date(timing.endDateTime)) : null;

      formGroup.addControl(
        'timeslot',
        this.timeslotType === TimeslotType.RANGE
          ? new UntypedFormGroup(
              {
                start: new UntypedFormControl(startValue, Validators.required),
                end: new UntypedFormControl(endValue, Validators.required),
                type: new UntypedFormControl(this.timeslotType),
              },
              {
                validators: [endClockTimeAfterStartClockTimeValidator('start', 'end')],
              }
            )
          : new UntypedFormGroup({
              start: new UntypedFormControl(startValue, Validators.required),
              type: new UntypedFormControl(this.timeslotType),
            })
      );
    }

    if (this.useDayparts) {
      let recurrenceTiming: RecurrenceTiming[];
      if (timing?.type === TimingType.DAY_PART) {
        recurrenceTiming = timings.map((t) => ({ dayPartName: t.dayPart }));
      } else {
        recurrenceTiming = [{ allDay: this.useDayPartAllDay }];
      }
      this.addDayparts(formGroup, recurrenceTiming, timing?.time);
    }

    return formGroup;
  }

  private getSelectionTypeControlName(monthRecurrence?: MonthRecurrence) {
    switch (monthRecurrence?.month?.selectionType) {
      case SelectionType.WEEKDAY:
        return 'weekday';
      case SelectionType.SELECTED_DAYS:
        return 'dayparts';
      case SelectionType.DAYS:
      default:
        return 'nthDay';
    }
  }

  private dateBetweenPeriodsOfApplicationValidator(control: AbstractControl): ValidationErrors | null {
    const filledInPeriodsOfApplication = this.periodsOfApplication?.filter((period) => period.startTime) ?? [];

    if (filledInPeriodsOfApplication.length === 0) {
      return null;
    }

    const valid = filledInPeriodsOfApplication.some((period) => {
      const value = typeof control.value === 'string' ? new Date(control.value) : control.value;
      return period.endTime
        ? (isBefore(period.startTime, value) || isSameDay(period.startTime, value)) &&
            (isBefore(value, period.endTime) || isSameDay(period.endTime, value))
        : isBefore(period.startTime, value) || isSameDay(period.startTime, value);
    });

    return valid ? null : { dateNotInPeriods: true };
  }

  private minOneDay(week: AbstractControl): ValidationErrors | null {
    const fa = week.get('days') as UntypedFormArray;
    if (week.get('_selectFrequencyOrWeekday')?.value === 'weekday' && fa.length === 0) {
      return { minOneDay: true };
    }
    return null;
  }

  private minOncePerWeek(frequency: AbstractControl): ValidationErrors | null {
    if (frequency.parent?.get('_selectFrequencyOrWeekday')?.value === 'frequency' && !frequency.value) {
      return { required: true };
    }
    return null;
  }
}
