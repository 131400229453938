import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ConfirmDirective } from '@nexuzhealth/shared/ui-toolkit/confirm';

@Component({
  selector: 'nxh-ellipsis-actions',
  templateUrl: './ellipsis-actions.component.html',
  styleUrls: ['./ellipsis-actions.component.scss'],
})
export class EllipsisActionsComponent {
  @ViewChild(ConfirmDirective, { static: false }) confirmDirective: ConfirmDirective;

  @Input() showDropdown: boolean;
  @Input() loading: boolean;
  @Input() placeholder: string;
  @Input() container: null | 'body' = null;

  @Input() editOption: boolean;
  @Input() editLink: any[] | string | null | undefined;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onEdit = new EventEmitter();

  @Input() deleteOption: boolean;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onDelete = new EventEmitter();

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onOpenChange = new EventEmitter();

  constructor() {}

  edit() {
    this.onEdit.emit();
  }

  delete() {
    this.onDelete.emit();
  }

  dropdownChangedEvent(opened: boolean) {
    this.onOpenChange.emit(opened);
    if (opened) {
      return;
    }
  }

  closeConfirm() {
    this.confirmDirective?.close();
  }
}
