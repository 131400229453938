import { Component, HostBinding, Input } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'nxh-dl',
  template: `
    <ng-template #content><ng-content></ng-content></ng-template>

    <ng-container *ngIf="icon">
      <nxh-dl-row>
        <nxh-dl-key [keySize]="'XS'">
          <fa-icon [icon]="icon" />
        </nxh-dl-key>
        <nxh-dl-val>
          <nxh-dl>
            <ng-container *ngTemplateOutlet="content" ngProjectAs="nxh-dl-row"></ng-container>
          </nxh-dl>
        </nxh-dl-val>
      </nxh-dl-row>
    </ng-container>

    <ng-container *ngIf="!icon">
      <ng-container *ngTemplateOutlet="content" ngProjectAs="nxh-dl-row"></ng-container>
    </ng-container>
  `,
})
export class DataListComponent {
  @HostBinding('class.data-list') clazz = true;
  @Input() keySize: 'XS' | 'S' | 'MD' = 'MD';
  @Input() icon: IconProp;
}
