import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Range } from '@nexuzhealth/shared/domain';
import { NgbCalendar, NgbDate, NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { NgbDatepickerNavigateEvent } from '@ng-bootstrap/ng-bootstrap/datepicker/datepicker';
import { jsDateToNgbDate, ngbDateToJsDate } from '../ngb-date-utils';

@Component({
  selector: 'nxh-mini-calendar',
  templateUrl: './mini-calendar.component.html',
  styleUrls: ['./mini-calendar.component.scss'],
  exportAs: 'miniCalendar',
})
export class MiniCalendarComponent implements AfterViewInit {
  @Input() mode: 'single' | 'range' = 'single';

  /**
   * Prevents highlighting cells that are in range when starting a range by clicking a startDate and hovering to an
   * toDate, as this *might* impact performance (all cells need to be rerendered at each hover). This property is
   * only relevant for mode 'range'
   */
  @Input() noHighlightInRangeOnHover = false;

  /**
   * Prevents highlighting the focused cell. This could be handly in the rare case when you want to highlight a
   * 'range' (e.g. of a whole week) on clicking a particular date. Only relevant for mode 'single'.
   */
  @Input() noHighlightOnFocused = false;

  @Output() dateSelection = new EventEmitter<Date>();
  @Output() rangeSelection = new EventEmitter<Range>();
  @Output() navigate = new EventEmitter<Date>();

  @ViewChild(NgbDatepicker, { static: true }) datepicker!: NgbDatepicker;

  hoveredDate: NgbDate | null = null;
  private _bullets: MiniBullet[] = [];
  _range!: { fromDate: NgbDate; toDate: NgbDate } | null;

  constructor(private calendar: NgbCalendar) {}

  @Input() set bullets(bullets: { date: Date; status: string; tooltip?: string }[]) {
    this._bullets = bullets?.map((bullet) => ({ ...bullet, date: jsDateToNgbDate(bullet.date) })) ?? [];
  }

  @Input() set range(range: Range) {
    this._range = range ? { fromDate: jsDateToNgbDate(range.fromDate), toDate: jsDateToNgbDate(range.toDate) } : null;
  }

  navigateToMonth(number: number) {
    const { state, calendar } = this.datepicker;
    this.datepicker.navigateTo(calendar.getNext(state.firstDate, 'm', number));
  }

  navigateToDate(date: Date) {
    const ngbDate = jsDateToNgbDate(date);
    this.datepicker.navigateTo(ngbDate);
  }

  selectDate(date: Date) {
    this.datepicker.focusDate(jsDateToNgbDate(date));
    this.datepicker.focusSelect();
  }

  selectToday() {
    const { calendar } = this.datepicker;
    this.datepicker.navigateTo(calendar.getToday());
  }

  getBullet(date: NgbDate) {
    return this._bullets?.find((bullet) => bullet.date.equals(date));
  }

  onDateSelection(date: NgbDate) {
    if (this.mode === 'range') {
      this._range = this.getRange(date);
      this.rangeSelection.emit({
        fromDate: ngbDateToJsDate(this._range.fromDate),
        toDate: ngbDateToJsDate(this._range.toDate),
      });
    } else {
      this.dateSelection.emit(ngbDateToJsDate(date));
    }
  }

  onMouseEnter(date: NgbDate) {
    if (this.mode === 'range' && !this.noHighlightInRangeOnHover) this.hoveredDate = date;
  }

  onMouseLeave() {
    if (this.mode === 'range' && !this.noHighlightInRangeOnHover) this.hoveredDate = null;
  }

  private getRange(date: NgbDate) {
    const fromDate = this._range?.fromDate;
    const toDate = this._range?.toDate;
    if (!fromDate && !toDate) {
      return { fromDate: date, toDate: toDate };
    } else if (fromDate && !toDate && date.after(fromDate)) {
      return { fromDate: fromDate, toDate: date };
    } else {
      return { fromDate: date, toDate: null };
    }
  }

  ngAfterViewInit(): void {
    // remove difficult-to-translate title attribute (an only be done w/angular translation)
    document.querySelectorAll('.btn.btn-link.ngb-dp-arrow-btn').forEach((btn) => btn.removeAttribute('title'));
  }

  onNavigate(event: NgbDatepickerNavigateEvent) {
    if (event.current !== null) {
      this.navigate.emit(ngbDateToJsDate(new NgbDate(event.next.year, event.next.month, 1)));
    }
  }
}

interface MiniBullet {
  date: NgbDate;
  status: string;
  tooltip?: string;
}
