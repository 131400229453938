import { Component, ContentChild, forwardRef, Input, OnInit, Optional } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DataTestDirective } from '@nexuzhealth/shared/tech/feature-e2e';
import { ToggleListOption, ToggleListOptionDirective } from '../toggle-list-option.directive';

@Component({
  selector: 'nxh-single-toggle-list',
  templateUrl: './single-toggle-list.component.html',
  styleUrls: ['./single-toggle-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SingleToggleListComponent),
      multi: true,
    },
  ],
})
export class SingleToggleListComponent implements ControlValueAccessor, OnInit {
  @Input() options!: ToggleListOption[];
  @Input() pills = false;
  @ContentChild(ToggleListOptionDirective) optionDirective!: ToggleListOptionDirective;

  formControl = new FormControl<string | null>(null);
  testPrefix = 'single-toggle-list';
  protected randomId = Math.random().toString();

  constructor(@Optional() private dataTestDirective: DataTestDirective) {}

  ngOnInit(): void {
    if (this.dataTestDirective) {
      this.testPrefix = this.dataTestDirective.nxhDataTest;
    }
  }

  registerOnChange(fn: any): void {
    this.formControl.valueChanges.subscribe((value) => fn(value));
  }

  registerOnTouched(fn: any): void {
    this.formControl.valueChanges.subscribe(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formControl.disable({ emitEvent: false });
    } else {
      this.formControl.enable({ emitEvent: false });
    }
  }

  writeValue(value: string): void {
    this.formControl.setValue(value, { emitEvent: false });
  }
}
