import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { rizivNumberValidator } from '@nexuzhealth/shared/ui-toolkit/forms';
import { AssignedIdTypenameEnum, HealthCareProfession, ListType, ResourceName } from '@nexuzhealth/shared/domain';
import {
  HealthcareworkerProfessionsQuery,
  PractitionerApiService,
  PractitionerService,
  Professional,
} from '@nexuzhealth/shared/practitioner/data-access';
import { PagingService } from '@nexuzhealth/shared/ui-toolkit/paging';
import { TableLoaderComponent } from '@nexuzhealth/shared/ui-toolkit/loading-states/table-loader';
import { SortModel } from '@nexuzhealth/shared/ui-toolkit/table';
import { ToastrHelperService } from '@nexuzhealth/shared/util';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { I18NextPipe } from 'angular-i18next';
import { sortBy } from 'lodash-es';
import { BehaviorSubject, combineLatest, EMPTY, Observable, Subject } from 'rxjs';
import { catchError, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { PractitionerSearchModalOptions } from './practitioner-search-modal-options';

@Component({
  selector: 'nxh-practitioner-search-modal',
  templateUrl: './practitioner-search-modal.component.html',
  styleUrls: ['./practitioner-search-modal.component.scss'],
  providers: [PagingService],
})
export class PractitionerSearchModalComponent implements OnInit, OnDestroy {
  @ViewChild('tableLoader', { static: true, read: TableLoaderComponent }) tableLoader: TableLoaderComponent;
  activeId = -1;
  practitioners$ = new BehaviorSubject([]);
  destroySubj = new Subject<void>();
  professions$: Observable<any>;
  busy$$ = new Subject<boolean>();
  displayedColumns = ['name', 'given', 'riziv', 'profession'];
  sortModel = new SortModel<any>();
  submitSub = new Subject<void>();
  hasSearched = false;
  formValues = null;
  readonly rizivFormatType = AssignedIdTypenameEnum.BE_RIZIV;
  selectedRow: Professional | undefined;

  form = new UntypedFormGroup({
    givenName: new UntypedFormControl(),
    name: new UntypedFormControl(null, Validators.minLength(2)),
    nihii: new UntypedFormControl(null, rizivNumberValidator),
    professionCode: new UntypedFormControl(),
  });

  constructor(
    private pagingService: PagingService<Professional>,
    private activeModal: NgbActiveModal,
    private practitionerSearchModalOptions: PractitionerSearchModalOptions,
    private toastrHelper: ToastrHelperService,
    private i18next: I18NextPipe,
    private practitionerApiService: PractitionerApiService,
    private practitionerService: PractitionerService,
    private healthcareworkerProfessionsQuery: HealthcareworkerProfessionsQuery
  ) {}

  public close() {
    this.activeModal.close();
  }

  ngOnInit(): void {
    const prof = this.practitionerSearchModalOptions.professions
      ? this.practitionerSearchModalOptions.professions.map((pr) => 'healthcareworkerprofessions/' + pr.split('/')[1])
      : null;
    this.sortModel.setValue({ sortBy: 'date', sortByOrder: 'desc' });
    this.practitionerService.loadHealthCareWorkerProfessionsForType(ListType.Addressbook);

    this.professions$ = this.healthcareworkerProfessionsQuery
      .selectHealthCareWorkerProfessionsForListType(ListType.Addressbook)
      .pipe(map((hwps) => sortBy(this.addTranslations(hwps), ['label'])));

    combineLatest([this.pagingService.pageChanges$, this.submitSub])
      .pipe(
        takeUntil(this.destroySubj),
        tap(() => {
          this.activeId = -1;
          this.hasSearched = true;
          this.tableLoader.startLoading();
        }),
        switchMap(([pageEvent]) => {
          const requestFn = (token: ResourceName) =>
            this.practitionerService.findHealthCareProfessional(this.formValues, token, pageEvent.pageSize);
          return this.pagingService.getPage(requestFn).pipe(
            catchError((err) => {
              let errMsg: string;
              switch (err.kind) {
                case 'ehealth_utils_no-current-practitioner':
                  errMsg = '_pract-search-modal.find-failed-no-current-practitioner';
                  break;
                case 'ehealth_sts_no-certificate':
                  errMsg = '_pract-search-modal.find-failed-cert-message';
                  break;
                default:
                  errMsg = '_pract-search-modal.find-failed-other';
                  break;
              }
              this.toastrHelper.error(errMsg, '_pract-search-modal.find-failed-title');
              this.tableLoader.setLoaded(0);
              this.busy$$.next(false);
              return EMPTY;
            })
          );
        })
      )
      .subscribe({
        next: (result) => {
          // flatten results
          const results = [];
          result.forEach((res) => {
            const professions = res.professions;
            delete res.professions;
            professions.forEach((_prof) => {
              results.push({ ...res, ..._prof });
            });
          });
          this.practitioners$.next(results);
          this.tableLoader.setLoaded(result.length);
        },
        error: (err) => {
          this.tableLoader.setError(err);
        },
      });

    if (prof?.length === 1) {
      this.form.get('professionCode').patchValue(prof[0]);
    }
  }

  ngOnDestroy(): void {
    this.destroySubj.next();
    this.destroySubj.complete();
  }

  submit() {
    this.form.markAllAsTouched();
    if (
      this.form.valid &&
      ((this.form.value.name && this.form.value.professionCode) ||
        (this.form.value.nihii && this.form.get('nihii').valid))
    ) {
      this.formValues = this.form.getRawValue();
      this.pagingService.reset();
      this.form.setErrors(null);
      this.submitSub.next();
      return;
    }
    this.form.setErrors({ invalidData: true });
  }

  selectRow(row: Professional, index: number) {
    if (row.nihii.length === 0) {
      this.activeId = -1;
      this.selectedRow = null;
      this.toastrHelper.error('_pract-search-modal.practitioner-no-nihii');
      return;
    }
    this.activeId = index;
    this.selectedRow = row;
  }

  confirm() {
    this.busy$$.next(true);
    this.practitionerApiService.createPractitionerFromProfessional(this.selectedRow.nihii).subscribe({
      next: (practitioner) => {
        this.activeModal.close(practitioner);
        this.busy$$.next(false);
      },
      error: (err) => {
        this.toastrHelper.error('_pract-search-modal.create-failed-body', '_pract-search-modal.create-failed-title');
        this.busy$$.next(false);
      },
    });
  }

  private addTranslations(values: HealthCareProfession[]) {
    return values?.map((v) => this.addTranslation(v));
  }

  private addTranslation(value: HealthCareProfession) {
    return {
      ...value,
      label: this.i18next.transform(value.translationKey)?.toLowerCase(),
    };
  }
}
