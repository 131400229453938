import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Error, Practitioner } from '@nexuzhealth/shared/domain';

export interface PractitionerManagementState {
  practitioner: Practitioner;
  successfulEdit: boolean;
  pageError: Error | null;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'practitioner-management', resettable: true })
export class PractitionerManagementStore extends Store<PractitionerManagementState | null> {
  constructor() {
    super({} as PractitionerManagementState);
  }
}
