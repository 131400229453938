import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedUtilI18nModule } from '@nexuzhealth/shared/util';
import { SharedTechFeatureE2eModule } from '@nexuzhealth/shared/tech/feature-e2e';
import { ToggleGroupDirective } from './toggle-group.directive';
import { MultiToggleListComponent } from './multi-toggle-list/multi-toggle-list.component';
import { ToggleListOptionDirective } from './toggle-list-option.directive';
import { ToggleOptionDirective } from './toggle-option.directive';
import { SingleToggleListComponent } from './single-toggle-list/single-toggle-list.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, SharedUtilI18nModule, SharedTechFeatureE2eModule],
  declarations: [
    ToggleOptionDirective,
    ToggleGroupDirective,
    MultiToggleListComponent,
    ToggleListOptionDirective,
    SingleToggleListComponent,
  ],
  exports: [
    ToggleOptionDirective,
    ToggleGroupDirective,
    MultiToggleListComponent,
    ToggleListOptionDirective,
    SingleToggleListComponent,
  ],
})
export class ToggleModule {}
