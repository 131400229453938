import { PagingModule } from '@nexuzhealth/shared/ui-toolkit/paging';
import { EmptyStateModule } from '@nexuzhealth/shared/ui-toolkit/loading-states/empty-state';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AlertModule } from '@nexuzhealth/shared/ui-toolkit/alert';
import { SharedUtilI18nModule } from '@nexuzhealth/shared/util';
import { NgbModalModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { SelectsModule } from '@nexuzhealth/shared/ui-toolkit/selects';
import { NxhFormsModule } from '@nexuzhealth/shared/ui-toolkit/forms';
import { CdkTableModule } from '@angular/cdk/table';
import { SharedReferenceDataFeatureAddressModule } from '@nexuzhealth/shared/reference-data/feature-address';
import { SharedReferenceDataDataAccessModule } from '@nexuzhealth/shared/reference-data/data-access';
import { TableLoaderModule } from '@nexuzhealth/shared/ui-toolkit/loading-states/table-loader';
import { FindAssignedIdTypePipeModule } from '@nexuzhealth/shared/ui-toolkit/pipes/find-assigned-id-type';
import { FormatAssignedIdPipeModule } from '@nexuzhealth/shared/ui-toolkit/pipes/format-assigned-id';
import { BoldNumberPipeModule } from '@nexuzhealth/shared/ui-toolkit/pipes/bold-number';
import { ButtonModule } from '@nexuzhealth/shared/ui-toolkit/button';
import { NgxMaskDirective } from 'ngx-mask';
import { IconsModule } from '@nexuzhealth/shared/ui-toolkit/icons';
import { PractitionerSearchComponent } from './practitioner-search/practitioner-search.component';
import { PractitionerSearchModalComponent } from './practitioner-search-modal/practitioner-search-modal.component';
import { PractitionerUserSearchComponent } from './practitioner-user-search/practitioner-user-search.component';
import { PractitionerUserContextSearchComponent } from './practitioner-user-context-search/practitioner-user-context-search.component';
import { PractitionerContextSearchComponent } from './practitioner-context-search/practitioner-context-search.component';

@NgModule({
  imports: [
    PagingModule,
    EmptyStateModule,

    CommonModule,
    ReactiveFormsModule,
    SharedUtilI18nModule,
    SelectsModule,
    NgbTooltipModule,
    NgbModalModule,
    CdkTableModule,
    NxhFormsModule,
    SharedReferenceDataFeatureAddressModule,
    SharedReferenceDataDataAccessModule,
    AlertModule,
    TableLoaderModule,
    FindAssignedIdTypePipeModule,
    FormatAssignedIdPipeModule,
    BoldNumberPipeModule,
    NgxMaskDirective,
    ButtonModule,
    IconsModule,
    // TODO ADD THIS IMPORT BACK WHEN MERGED TO ANGULAR AND NX 16
    // NgOptionHighlightModule,
  ],
  declarations: [
    PractitionerSearchComponent,
    PractitionerSearchModalComponent,
    PractitionerContextSearchComponent,
    PractitionerUserSearchComponent,
    PractitionerUserContextSearchComponent,
  ],
  exports: [
    PractitionerSearchComponent,
    PractitionerContextSearchComponent,
    PractitionerUserSearchComponent,
    PractitionerUserContextSearchComponent,
  ],
})
export class SharedPractitionerFeatureSearchModule {}
