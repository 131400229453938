import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FormHelper } from '@nexuzhealth/shared/ui-toolkit/forms';
import { DEFAULT_RECURRENCE_VALUES } from '@nexuzhealth/shared/ui-toolkit/recurrence';
import { of } from 'rxjs';

@Component({
  templateUrl: './recurrence-demo.component.html',
  styleUrls: ['./recurrence-demo.component.scss'],
})
export class RecurrenceDemoComponent implements OnInit {
  public form: UntypedFormGroup;

  setupCode = `
      <nh-control [label]="'recurrences'">
        <nxh-recurrences formControlName="recurrences" [useTimeslots]="true"></nxh-recurrences>
      </nh-control>
      
      this.form = new FormGroup({
        recurrences: new FormControl(DEFAULT_RECURRENCE_VALUES.WEEK),
      });
  `;
  constructor() {}

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      whatever: new UntypedFormControl(),
      recurrences: new UntypedFormControl(DEFAULT_RECURRENCE_VALUES.WEEK),
    });
  }

  setDay() {
    this.form.setValue({
      whatever: null,
      recurrences: {
        name: null,
        recurrences: [
          {
            period: 3,
            day: {
              timings: [
                {
                  timeslot: {
                    start: {
                      hours: 3,
                      minutes: 0,
                      seconds: 0,
                    },
                    end: {
                      hours: 5,
                      minutes: 0,
                      seconds: 0,
                    },
                  },
                },
                {
                  timeslot: {
                    start: {
                      hours: 0,
                      minutes: 0,
                      seconds: 0,
                    },
                    end: {
                      hours: 1,
                      minutes: 0,
                      seconds: 0,
                    },
                  },
                },
              ],
            },
          },
        ],
      },
    });
  }

  setWeek() {
    this.form.setValue({
      whatever: null,
      recurrences: {
        name: null,
        recurrences: [
          {
            period: 2,
            week: {
              days: [
                {
                  weekday: 'thursday',
                  timings: [
                    {
                      timeslot: {
                        start: { hours: 2, minutes: 0, seconds: 0 },
                        end: { hours: 2, minutes: 0, seconds: 0 },
                      },
                    },
                  ],
                },
              ],
            },
          },
        ],
        timings: [],
      },
    });
  }

  setMonth1() {
    this.form.setValue({
      whatever: null,
      recurrences: {
        name: null,
        recurrences: [
          {
            period: 3,
            month: {
              days: [
                {
                  nthDay: 2,
                  timings: [
                    {
                      timeslot: {
                        start: {
                          hours: 1,
                          minutes: 0,
                          seconds: 0,
                        },
                        end: {
                          hours: 2,
                          minutes: 0,
                          seconds: 0,
                        },
                      },
                    },
                    {
                      timeslot: {
                        start: {
                          hours: 3,
                          minutes: 0,
                          seconds: 0,
                        },
                        end: {
                          hours: 4,
                          minutes: 0,
                          seconds: 0,
                        },
                      },
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    });
  }

  setMonth2() {
    this.form.setValue({
      whatever: null,
      recurrences: {
        name: null,
        recurrences: [
          {
            period: 3,
            month: {
              days: [
                {
                  weekday: {
                    nth: 2,
                    weekday: 'tuesday',
                  },
                  timings: [
                    {
                      timeslot: {
                        start: {
                          hours: 1,
                          minutes: 0,
                          seconds: 0,
                        },
                        end: {
                          hours: 2,
                          minutes: 0,
                          seconds: 0,
                        },
                      },
                    },
                    {
                      timeslot: {
                        start: {
                          hours: 3,
                          minutes: 0,
                          seconds: 0,
                        },
                        end: {
                          hours: 4,
                          minutes: 0,
                          seconds: 0,
                        },
                      },
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    });
  }

  setManual() {
    this.form.setValue({
      whatever: null,
      recurrences: {
        name: null,
        recurrences: [],
        timings: [
          {
            type: 'RANGE',
            dateTime: new Date(),
            endDateTime: new Date(),
            timeZone: 'Europe/Brussels',
            eventTypeName: '',
          },
          {
            type: 'RANGE',
            dateTime: new Date(),
            endDateTime: new Date(),
            timeZone: 'Europe/Brussels',
            eventTypeName: '',
          },
        ],
      },
    });
  }

  onSubmit(fh: FormHelper) {
    console.log('yeah');
    fh.submit(() => of());
  }
}
