import { Component, OnInit } from '@angular/core';
import { ChartData } from '@nexuzhealth/shared/ui-toolkit/charts';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WeatherService } from './weather.service';

@Component({
  selector: 'nxh-weather',
  templateUrl: './weather.component.html',
  styleUrls: ['./weather.component.scss'],
})
export class WeatherComponent implements OnInit {
  data$: Observable<ChartData>;
  constructor(private _weather: WeatherService) {}

  ngOnInit() {
    this.data$ = this._weather.dailyForecast().pipe(
      map((res) => {
        const values = [];
        const tooltips = [];
        const weatherDates: string[] = [];

        const temp_max = res['list'].map((_res) => _res.main.temp_max);
        const temp_min = res['list'].map((_res) => _res.main.temp_min);
        const alldates = res['list'].map((_res) => _res.dt);
        values.push(temp_max);

        values.push(temp_min);
        alldates.forEach((_res) => {
          const jsdate = new Date(_res * 1000);
          weatherDates.push(jsdate.toLocaleTimeString('nl', { year: 'numeric', month: 'short', day: 'numeric' }));
          tooltips.push({
            title:
              'this is the title for ' +
              jsdate.toLocaleTimeString('nl', { year: 'numeric', month: 'short', day: 'numeric' }),
            content: 'wow cool',
          });
        });

        return { datasets: values, labels: weatherDates, tooltips };
      })
    );
  }
}
