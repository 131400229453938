import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { SharedTechFeatureE2eModule } from '@nexuzhealth/shared/tech/feature-e2e';
import { IconsModule } from '@nexuzhealth/shared/ui-toolkit/icons';
import { DataListComponent } from './data-list.component';
import { DataListRowComponent } from './data-list-row.component';
import { DataListKeyComponent } from './data-list-key.component';
import { DataListValComponent } from './data-list-val.component';

@NgModule({
  imports: [CommonModule, SharedTechFeatureE2eModule, IconsModule],
  declarations: [DataListComponent, DataListRowComponent, DataListKeyComponent, DataListValComponent],
  exports: [DataListComponent, DataListRowComponent, DataListKeyComponent, DataListValComponent],
})
export class DataListModule {}
