import { Component } from '@angular/core';
import { ScrollspyService } from '@nexuzhealth/shared/ui-toolkit/scrollspy';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'nxh-collapse-demo',
  templateUrl: './collapse-demo.component.html',
  styleUrls: ['./collapse-demo.component.scss'],
})
export class CollapseDemoComponent {
  selected$ = new BehaviorSubject('basic');

  showCard = true;
  cardCode = `<nxh-collapse>
  <div class="card">
    <div class="card-header card-header--large">
      <div class="card-header__title">This is a basic card header!</div>
      <div class="card-header__controls">
        <nxh-collapse-chevron-button></nxh-collapse-chevron-button>
      </div>
    </div>
    <nxh-collapse-panel>
      <div class="card-body">
        <div class="card-body__container">
          Cras tincidunt, enim non imperdiet imperdiet, magna nisl pharetra est, sed tincidunt libero turpis
          quis felis. Morbi quis justo metus. Aliquam orci turpis, ornare in ante quis, condimentum hendrerit
          erat. Nulla lectus augue, posuere at egestas eget, semper imperdiet ex. Suspendisse ullamcorper
          hendrerit ipsum eu tristique. Nam a elit interdum erat finibus viverra. Sed ac dolor ex.
        </div>
        <div class="card-body__container">
          Cras tincidunt, enim non imperdiet imperdiet, magna nisl pharetra est, sed tincidunt libero turpis
          quis felis. Morbi quis justo metus. Aliquam orci turpis, ornare in ante quis, condimentum hendrerit
          erat. Nulla lectus augue, posuere at egestas eget, semper imperdiet ex. Suspendisse ullamcorper
          hendrerit ipsum eu tristique. Nam a elit interdum erat finibus viverra. Sed ac dolor ex.
        </div>
      </div>
    </nxh-collapse-panel>
  </div>
</nxh-collapse>`;

  showCardFull = true;
  cardFullCode = `<nxh-collapse>
  <div class="card">
    <div class="card-header card-header--large">
      <div class="card-header__title" nxhCollapseToggle>
        This is a basic card header!
        <nxh-collapse-chevron></nxh-collapse-chevron>
      </div>
    </div>
    <nxh-collapse-panel>
      <div class="card-body">
        <div class="card-body__container">
          Cras tincidunt, enim non imperdiet imperdiet, magna nisl pharetra est, sed tincidunt libero turpis
          quis felis. Morbi quis justo metus. Aliquam orci turpis, ornare in ante quis, condimentum hendrerit
          erat. Nulla lectus augue, posuere at egestas eget, semper imperdiet ex. Suspendisse ullamcorper
          hendrerit ipsum eu tristique. Nam a elit interdum erat finibus viverra. Sed ac dolor ex.
        </div>
      </div>
    </nxh-collapse-panel>
  </div>
</nxh-collapse>`;

  showHighlightBlock = true;
  highlightBlockCode = `<nxh-collapse>
  <div class="highlight-block">
    <div class="highlight-block__title highlight-block__title--collapse" nxhCollapseToggle>
      Show hidden content
      <nxh-collapse-chevron></nxh-collapse-chevron>
    </div>
    <nxh-collapse-panel>
      <p>
        Cras tincidunt, enim non imperdiet imperdiet, magna nisl pharetra est, sed tincidunt libero turpis
        quis felis. Morbi quis justo metus. Aliquam orci turpis, ornare in ante quis, condimentum
        hendrerit erat. Nulla lectus augue, posuere at egestas eget, semper imperdiet ex. Suspendisse
        ullamcorper hendrerit ipsum eu tristique. Nam a elit interdum erat finibus viverra. Sed ac dolor
        ex.
      </p>
    </nxh-collapse-panel>
  </div>
</nxh-collapse>`;

  showDefaultOpen = true;
  defaultOpenCode = `<nxh-collapse [defaultOpen]="true">
  <div class="highlight-block">
    <div class="highlight-block__title highlight-block__title--collapse" nxhCollapseToggle>
      Show hidden content
      <nxh-collapse-chevron></nxh-collapse-chevron>
    </div>
    <nxh-collapse-panel>
      <p>
        Cras tincidunt, enim non imperdiet imperdiet, magna nisl pharetra est, sed tincidunt libero turpis
        quis felis. Morbi quis justo metus. Aliquam orci turpis, ornare in ante quis, condimentum
        hendrerit erat. Nulla lectus augue, posuere at egestas eget, semper imperdiet ex. Suspendisse
        ullamcorper hendrerit ipsum eu tristique. Nam a elit interdum erat finibus viverra. Sed ac dolor
        ex.
      </p>
    </nxh-collapse-panel>
  </div>
</nxh-collapse>`;

  showLinkButton = true;
  linkButtonCode = `<nxh-collapse>
  <nxh-collapse-link-button>Show hidden content</nxh-collapse-link-button>
  <nxh-collapse-panel>
    Cras tincidunt, enim non imperdiet imperdiet, magna nisl pharetra est, sed tincidunt libero turpis quis felis.
    Morbi quis justo metus. Aliquam orci turpis, ornare in ante quis, condimentum hendrerit erat. Nulla lectus
    augue, posuere at egestas eget, semper imperdiet ex. Suspendisse ullamcorper hendrerit ipsum eu tristique. Nam
    a elit interdum erat finibus viverra. Sed ac dolor ex.
  </nxh-collapse-panel>
</nxh-collapse>`;

  showWidgetHeader = true;
  widgetHeaderCode = `<nxh-collapse>
  <div class="summary-widget-header" style="cursor: inherit;">
    <div class="summary-widget-header__title">
      <fa-icon [icon]="['fas', 'bookmark']"></fa-icon>
      <button class="btn btn-link">
        {{ '_health-elements._summary.title' | i18next }}
      </button>
    </div>
    <div class="summary-widget-header__controls">
      <nxh-collapse-chevron-button></nxh-collapse-chevron-button>
    </div>
  </div>
  <nxh-collapse-panel>
    Cras tincidunt, enim non imperdiet imperdiet, magna nisl pharetra est, sed tincidunt libero turpis
    quis felis. Morbi quis justo metus. Aliquam orci turpis, ornare in ante quis, condimentum hendrerit
    erat. Nulla lectus augue, posuere at egestas eget, semper imperdiet ex. Suspendisse ullamcorper
    hendrerit ipsum eu tristique. Nam a elit interdum erat finibus viverra. Sed ac dolor ex.
  </nxh-collapse-panel>
</nxh-collapse>`;

  controlledIsOpen = false;
  showControlledCollapse = true;
  controlledCollapseCode = `// controlledToggle() {
//   setTimeout(() => (this.controlledIsOpen = !this.controlledIsOpen), 1000);
// }
<button class="btn btn-warning" (click)="controlledToggle()">Toggle after 1 second</button>
<nxh-collapse [isOpen]='controlledIsOpen'>
  <nxh-collapse-panel>
    Cras tincidunt, enim non imperdiet imperdiet, magna nisl pharetra est, sed tincidunt libero turpis
    quis felis. Morbi quis justo metus. Aliquam orci turpis, ornare in ante quis, condimentum hendrerit
    erat. Nulla lectus augue, posuere at egestas eget, semper imperdiet ex. Suspendisse ullamcorper
    hendrerit ipsum eu tristique. Nam a elit interdum erat finibus viverra. Sed ac dolor ex.
  </nxh-collapse-panel>
</nxh-collapse>`;

  showCustomCollapse = true;
  customCollapseCode = `<nxh-collapse #customCollapse (onChange)="doSomething($event)">
  <button class="btn btn-primary" nxhCollapseToggle>
    {{ (customCollapse.isOpen$ | async) ? 'Hide content' : 'Show hidden content' }}
    <nxh-collapse-chevron></nxh-collapse-chevron>
  </button>
  <nxh-collapse-panel>
    Cras tincidunt, enim non imperdiet imperdiet, magna nisl pharetra est, sed tincidunt libero turpis
    quis felis. Morbi quis justo metus. Aliquam orci turpis, ornare in ante quis, condimentum hendrerit
    erat. Nulla lectus augue, posuere at egestas eget, semper imperdiet ex. Suspendisse ullamcorper
    hendrerit ipsum eu tristique. Nam a elit interdum erat finibus viverra. Sed ac dolor ex.
  </nxh-collapse-panel>
</nxh-collapse>`;

  constructor(private scrollspyService: ScrollspyService) {}

  scrollTo(id) {
    this.selected$.next(id);
    this.scrollspyService.scrollTo(id, { emitEvent: false });
  }

  scrolledInView(id) {
    this.selected$.next(id);
  }

  controlledToggle() {
    setTimeout(() => (this.controlledIsOpen = !this.controlledIsOpen), 1000);
  }

  log(isOpen: boolean) {
    console.log('custom collapse is open?', isOpen);
  }
}
