import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FocusService } from '@nexuzhealth/shared/ui-toolkit/focus';

@Component({
  selector: 'nxh-focus-demo',
  templateUrl: './focus-demo.component.html',
  styleUrls: ['./focus-demo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FocusDemoComponent implements OnInit {
  code1 = `<input type="text" nxhFocus/>`;
  code2 = `<input type="text" nxhFocus [auto]="expr"/>`;
  code3 = `
    <input type="text" nxhFocus [auto]="expr" handle="'firstName'"/>
    FocusService.focus('firstName);
  `;

  showAutofocus = false;
  inputCode = `<input type="text" class="form-control" nxhFocus />`;
  cvaCode = `

  @Component({
    ...
    providers: [{ provide: FOCUSSABLE, useExisting: forwardRef(() => NumberFieldComponent) }]
  })
  export class NumberFieldComponent implements ControlValueAccessor, Focusable {
    ...

    @ViewChild('input', { static: true }) elementRef: ElementRef<HTMLInputElement>; // <-- reference to the input

    ...

    setFocus() {
      this.elementRef.nativeElement.focus();
    }

  }

  <nxh-number-field step="0.1" suffix="°C" nxhFocus> </nxh-number-field>`;

  fc = new UntypedFormControl();

  constructor(private focusService: FocusService) {}

  ngOnInit() {
    this.fc.valueChanges.subscribe((value) => {
      if (value === 'moapr') {
        this.focusService.focus('field-2');
      }
    });
  }

  setFocus(handleName) {
    this.focusService.focus(handleName);
  }
}
