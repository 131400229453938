import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { filter, map } from 'rxjs/operators';
import { HealthCareProfession, ListType } from '@nexuzhealth/shared/domain';
import { Observable } from 'rxjs';
import {
  HealthcareworkerProfessionsState,
  HealthcareworkerProfessionsStore,
} from './healthcareworker-professions.store';

@Injectable({
  providedIn: 'root',
})
export class HealthcareworkerProfessionsQuery extends Query<HealthcareworkerProfessionsState> {
  constructor(protected store: HealthcareworkerProfessionsStore) {
    super(store);
  }

  getHealthCareWorkerProfessionsForListType(type: ListType): HealthCareProfession[] | null {
    return this.getValue().professionsPerListType[type];
  }

  selectHealthCareWorkerProfessionsForListType(type: ListType): Observable<HealthCareProfession[]> {
    return this.select().pipe(
      map((state) => state.professionsPerListType[type.toString()]),
      filter((professions) => professions?.length > 0)
    );
  }
}
