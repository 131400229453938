import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Practitioner } from '@nexuzhealth/shared/domain';

const idKey: keyof Practitioner = 'name';

export type PractitionerStateKey = Practitioner[typeof idKey];
export type PractitionerState = EntityState<Practitioner, PractitionerStateKey>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'practitioner', idKey, resettable: true })
export class PractitionerStore extends EntityStore<PractitionerState> {
  constructor() {
    super();
  }
}
