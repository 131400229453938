import { Injectable, OnDestroy } from '@angular/core';
import { PagingResult } from '@nexuzhealth/shared/domain';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'any' })
export class PagingService<T> implements OnDestroy {
  private readonly initialState: PagingState = Object.freeze({
    tokens: [null],
    total: 0,
    actualPageSize: 0,
    pageSize: 10,
    pageNumber: 0,
  });
  private stateSubj: BehaviorSubject<Readonly<PagingState>> = new BehaviorSubject(this.initialState);
  private loadingSubj = new BehaviorSubject(false);
  private triggerLoadSubj = new BehaviorSubject(true);

  total$ = this.stateSubj.asObservable().pipe(
    map(({ total }) => total),
    distinctUntilChanged()
  );

  hasNext$ = this.stateSubj.pipe(
    map((state) => {
      return state.tokens.length > state.pageNumber + 1 && !!state.tokens[state.pageNumber + 1];
    })
  );

  hasPrev$: Observable<boolean> = this.stateSubj.pipe(map((state) => state.pageNumber !== 0));

  pageChanges$: Observable<PageEvent> = this.triggerLoadSubj.pipe(
    filter((emit) => emit),
    map(() => {
      const state = this.stateSubj.getValue();
      return {
        token: state.tokens.length > 0 ? state.tokens[state.pageNumber] : null,
        pageSize: state.pageSize,
        pageNumber: state.pageNumber,
      };
    })
  );

  paginatorChanges$ = this.stateSubj.asObservable().pipe(
    map(({ pageSize, pageNumber, actualPageSize, total }) => ({ pageSize, pageNumber, actualPageSize, total })),
    distinctUntilChanged(
      (curr, prev) =>
        curr.pageSize === prev.pageSize &&
        curr.pageNumber === prev.pageNumber &&
        curr.actualPageSize === prev.actualPageSize &&
        curr.total === prev.total
    )
  );

  loading$ = this.loadingSubj.asObservable();

  setPageSize(pageSize: number = 10, options = { triggerLoad: false }) {
    this.stateSubj.next({ pageNumber: 0, tokens: [null], total: 0, pageSize, actualPageSize: 0 });
    if (options.triggerLoad) {
      this.triggerLoadSubj.next(true);
    }
  }

  next() {
    const state = this.stateSubj.getValue();
    this.stateSubj.next({
      ...state,
      pageNumber: state.pageNumber + 1,
    });
    this.triggerLoadSubj.next(true);
  }

  prev() {
    const state = this.stateSubj.getValue();
    this.stateSubj.next({
      ...state,
      actualPageSize: state.pageSize,
      pageNumber: state.pageNumber - 1,
    });
    this.triggerLoadSubj.next(true);
  }

  hasNext() {
    const state = this.stateSubj.getValue();
    return state.tokens.length > state.pageNumber + 1 && !!state.tokens[state.pageNumber + 1];
  }

  hasPrev() {
    return this.stateSubj.getValue().pageNumber !== 0;
  }

  reload() {
    this.triggerLoadSubj.next(true);
  }

  reset() {
    this.stateSubj.next(this.initialState);
  }

  clear() {
    const state = this.stateSubj.getValue();
    this.stateSubj.next({ ...state, pageNumber: 0 });
  }

  getPage(request: (token: string) => Observable<PagingResult<T>>, fallbackToPreviousPage = false): Observable<T[]> {
    let state = this.stateSubj.getValue();
    const token = state.tokens[state.pageNumber];

    this.loadingSubj.next(true);
    return request(token).pipe(
      tap((response) => {
        // get last instance of state
        state = this.stateSubj.getValue();

        //add token + remove all following tokens, otherwise we could get doubles
        const tokens = [...state.tokens];
        tokens.splice(state.pageNumber + 1, state.tokens.length - (state.pageNumber + 1), response.pageToken);

        //if we aren't on the first page and the result is empty go to the previous page
        if (response.data.length === 0 && state.pageNumber !== 0) {
          this.prev();
          return;
        }

        // the totalSize returned from BE can be undefined, or <number>, or "<number>", depending on the GOLANG typing.
        // In this step we make sure it actually is a number going forward.
        // For the time being, we consider 0 as 'no count query has run', even when a query has run and returned 0...
        // will probably be subject to change in the near future.
        const totalAsNumber = response.totalSize ? Number(response.totalSize) : 0;

        this.stateSubj.next({
          ...state,
          tokens,
          total: totalAsNumber,
          actualPageSize: (response.data || []).length,
        });
        this.loadingSubj.next(false);
      }),
      map((response) => response.data),
      catchError((err) => {
        if (this.hasPrev() && fallbackToPreviousPage) {
          this.stateSubj.next({ ...state, pageNumber: state.pageNumber - 1 });
        }
        this.loadingSubj.next(false);
        return throwError(err);
      })
    );
  }

  getState() {
    return this.stateSubj.getValue();
  }

  setState(state: PagingState) {
    this.stateSubj.next(state);
    this.triggerLoadSubj.next(true);
  }

  isLoading() {
    return this.loadingSubj.getValue();
  }

  updateState(mapper: (state: Readonly<PagingState>) => Readonly<PagingState>) {
    return this.setState(mapper(this.getState()));
  }

  getPageSize() {
    return this.stateSubj.getValue()?.pageSize;
  }

  ngOnDestroy(): void {
    this.stateSubj.complete();
    this.loadingSubj.complete();
    this.triggerLoadSubj.complete();
  }
}

export interface PageEvent {
  pageSize: number;
  token?: string;
}

export interface PagingState {
  pageSize: number;
  actualPageSize: number;
  pageNumber: number;
  total: number;
  tokens: any[];
}
