import { Component } from '@angular/core';
import { NavItem } from '@nexuzhealth/shared/domain';

@Component({
  selector: 'nxh-charts-demo',
  templateUrl: './charts-demo.component.html',
  styleUrls: ['./charts-demo.component.scss'],
})
export class ChartsDemoComponent {
  navItems: NavItem[] = [
    { routerLink: 'pijnschaal', label: 'pijnschaal' },
    { routerLink: 'bloeddruk', label: 'bloeddruk' },
    { routerLink: 'weather', label: 'weather' },
  ];

  code = `
    <nxh-line-chart
      [labels]="labels"
      [datasets]="datasets"
      (selectPoint)="onSelectPoint($event)"
    ></nxh-line-chart>
  `;

  changeColorCodeTS = `[
    {
      borderColor: '#51A5DE',
      pointBorderColor: '#51A5DE',
      pointHoverBorderColor: '#51A5DE',
      pointBackgroundColor: '#51A5DE',
      pointHoverBackgroundColor: '#51A5DE',
    },
    {
      borderColor: '#d12f30',
      pointBorderColor: '#d12f30',
      pointHoverBorderColor: '#d12f30',
      pointBackgroundColor: '#d12f30',
      pointHoverBackgroundColor: '#d12f30',
    },
  ]`;

  changeColorCode = `<nxh-line-chart [labels]="labels" [datasets]="values" [colors]="colors"></nxh-line-chart>`;
}
