<!--somehow i have to put #selectComponent on here to make my test work-->
<ng-select
  #selectComponent
  [placeholder]="placeholder | i18next"
  [items]="searchResult$ | async"
  [loading]="loading$ | async"
  [multiple]="multiple"
  [typeahead]="term$"
  [addTag]="search ? addTagFn : false"
  [compareWith]="getCompareOptions()"
  [trackByFn]="trackOption"
  class="ng-select--practitioner"
>
  <ng-template ng-label-tmp let-option="item">
    {{ option.formattedPersonName }} ({{ option.assignedIds | findAssignedIdType : RIZIV | formatAssignedId }})
  </ng-template>

  <ng-template ng-option-tmp let-option="item" let-search="searchTerm">
    <div class="practitioner-option__container">
      <div class="nh-row">
        <div class="nh-col personname" [ngOptionHighlight]="search">{{ option.formattedPersonName }}</div>
        <div
          class="nh-col riziv"
          [innerHTML]="option.assignedIds | findAssignedIdType : RIZIV | formatAssignedId | boldNumber : search"
        ></div>
      </div>
      <div class="nh-row">
        <div class="nh-col address">{{ option.address | formatAddress }}</div>
        <div class="nh-col specialty">
          {{ option.practice | resolveHealthCareWorkerProfession }}
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #addTagRef ng-tag-tmp let-search="searchTerm">
    <div class="ng-option--add-tag">
      <div>
        {{ '_practitioner-search.search-in-ehealth' | i18next }}
        <a href="" (click)="$event.preventDefault()">{{ '_practitioner-search.search-in-ehealth-link' | i18next }}</a>
      </div>
    </div>
  </ng-template>
</ng-select>
