import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { I18NextModule } from 'angular-i18next';
import { SharedTechFeatureE2eModule } from '@nexuzhealth/shared/tech/feature-e2e';
import { ConfirmModule } from '@nexuzhealth/shared/ui-toolkit/confirm';

import { IconsModule } from '@nexuzhealth/shared/ui-toolkit/icons';
import { ButtonModule } from '@nexuzhealth/shared/ui-toolkit/button';
import { EllipsisActionsComponent } from './ellipsis-actions.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    I18NextModule,
    NgbDropdownModule,
    IconsModule,

    ConfirmModule,
    SharedTechFeatureE2eModule,
    ButtonModule,
    NgbTooltipModule,
  ],
  declarations: [EllipsisActionsComponent],
  exports: [EllipsisActionsComponent],
})
export class SharedUiToolkitEllipsisActionsModule {}
