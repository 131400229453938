import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPlus, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'nxh-add-remove-buttons',
  templateUrl: './add-remove-buttons.component.html',
  styleUrls: ['./add-remove-buttons.component.scss'],
})
export class AddRemoveButtonsComponent {
  @Input() showAdd = false;
  @Input() addIcon: IconProp = faPlus;
  @Input() addIconToolTip;
  @Input() addDisabled = false;

  @Input() removeIcon: IconProp = faTrashAlt;
  @Input() removeIconToolTip;
  @Input() removeDisabled = false;
  @Input() colorRemoveIcon = 'var(--dangerColor)';
  @Input() disableRemoveConfirm = true;
  @Input() removeConfirmText;
  @Input() removeButtonText;

  @Output() add = new EventEmitter();
  @Output() remove = new EventEmitter<number>();
}
