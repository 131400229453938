import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { SharedTechFeatureE2eModule } from '@nexuzhealth/shared/tech/feature-e2e';
import { SharedUtilI18nModule } from '@nexuzhealth/shared/util';
import { RouterModule } from '@angular/router';
import { ConfirmModule } from '@nexuzhealth/shared/ui-toolkit/confirm';
import { IconsModule } from '@nexuzhealth/shared/ui-toolkit/icons';
import { ButtonModule } from '@nexuzhealth/shared/ui-toolkit/button';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { DetailBubbleDirective } from './detail-bubble.directive';
import { DetailBubbleComponent } from './detail-bubble/detail-bubble.component';

@NgModule({
  imports: [
    CommonModule,
    IconsModule,
    ConfirmModule,
    SharedTechFeatureE2eModule,
    SharedUtilI18nModule,
    RouterModule,
    ButtonModule,
    NgbTooltipModule,
  ],
  exports: [DetailBubbleComponent, DetailBubbleDirective],
  declarations: [DetailBubbleComponent, DetailBubbleDirective],
})
export class DetailModule {}
