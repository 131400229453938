import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ScrollspyService } from '@nexuzhealth/shared/ui-toolkit/scrollspy';
import { HotkeysService } from '@nexuzhealth/shared/ui-toolkit/hotkeys';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'nxh-hotkeys-demo',
  templateUrl: './hotkeys-demo.component.html',
  styleUrls: ['./hotkeys-demo.component.scss'],
})
export class HotkeysDemoComponent implements OnInit, OnDestroy {
  showExampleBlock1 = true;
  block1Code = `
  // TEMPLATE
  <button *ngIf="color$ | async as color" class="btn" [class]="'btn-' + color" (click)="openHelpModal()">
    <fa-icon icon="question-circle"></fa-icon>
    <div>Open hotkeys overview</div>
  </button>

  // TS
  destroy$ = new Subject<void>();
  color$ = new BehaviorSubject('primary');

  constructor(private hotkeys: HotkeysService, private router: Router) {}

  ngOnInit() {
    this.hotkeys
      .addShortcut({ keys: 'shift.a', description: 'Navigeer naar de homepage' })
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.router.navigate(['/']);
      });
    this.hotkeys
      .addShortcut({ keys: 'shift.p', description: 'Maak button primary' })
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.color$.next('primary');
      });
    this.hotkeys
      .addShortcut({ keys: 'shift.d', description: 'Maak button danger' })
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.color$.next('danger');
      });
    this.hotkeys
      .addShortcut({ keys: 'shift.w', description: 'Maak button warning' })
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.color$.next('warning');
      });
  }

  openHelpModal() {
    this.hotkeys.openHelpModal();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  `;

  selected$ = new BehaviorSubject('block1');
  destroy$ = new Subject<void>();
  color$ = new BehaviorSubject('primary');

  constructor(private scrollspyService: ScrollspyService, private hotkeys: HotkeysService, private router: Router) {}

  ngOnInit() {
    this.hotkeys
      .addShortcut({ keys: 'shift.a', description: 'Navigeer naar de homepage' })
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.router.navigate(['/']);
      });
    this.hotkeys
      .addShortcut({ keys: 'shift.p', description: 'Maak button primary' })
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.color$.next('primary');
      });
    this.hotkeys
      .addShortcut({ keys: 'shift.d', description: 'Maak button danger' })
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.color$.next('danger');
      });
    this.hotkeys
      .addShortcut({ keys: 'shift.w', description: 'Maak button warning' })
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.color$.next('warning');
      });
  }

  openHelpModal() {
    this.hotkeys.openHelpModal();
  }

  scrollTo(id) {
    this.selected$.next(id);
    this.scrollspyService.scrollTo(id, { emitEvent: false });
  }

  scrolledInView(id) {
    this.selected$.next(id);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
