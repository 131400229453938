import { Component, ViewChild } from '@angular/core';
import { EllipsisWithTooltipDirective } from '@nexuzhealth/shared/ui-toolkit/ellipsis';

@Component({
  templateUrl: './ellipsis-with-tooltip-demo.component.html',
  styleUrls: ['./ellipsis-with-tooltip-demo.component.scss'],
})
export class EllipsisWithTooltipDemoComponent {
  @ViewChild('ewt', { static: true, read: EllipsisWithTooltipDirective }) ewt: EllipsisWithTooltipDirective;

  content = `  The 9.0.0 release of Angular is here! This is a major release that spans the entire platform, including the framework,
  Angular Material, and the CLI.`;

  maxWidth = '80rem';
  defaultCode = `
    <div class="card tooltip-on-ellipsis-container" style="max-width: 80rem;">
    <span [ngbTooltip]="content" nxhEllipsisWithTooltip>
      {{ content }}
    </span>

    <span [ngbTooltip]="content" nxhEllipsisWithTooltip lines="3">
      {{ content }}
    </span>
  </div>`;

  dynamicCode = `
    <div class="card tooltip-on-ellipsis-container" [style.max-width]="maxWidth">
      <span [ngbTooltip]="content" nxhEllipsisWithTooltip #ewt>
        {{ content }}
      </span>
    </div>
    <button class="btn btn-primary" (click)="changeSize()">change size</button>

    @ViewChild('ewt', {static: true, read: EllipsisWithTooltipDirective}) ewt: EllipsisWithTooltipDirective

    changeSize() {
      this.maxWidth = '20rem'
      setTimeout(() => {
        this.ewt.detectTooltip();
      })
    }
  `;

  amount = 1;

  constructor() {}

  changeSize() {
    this.maxWidth = '20rem';
    setTimeout(() => {
      this.ewt.detectTooltip();
    });
  }
}
