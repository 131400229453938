import { TimeslotType, Timing, TimingType } from '@nexuzhealth/shared/domain';
import { jsDateToUtcDate } from '@nexuzhealth/shared/util';
import { TimingFormValue } from '../recurrences-form.domain';

export function mapToTiming(
  timingFormValue: TimingFormValue,
  useTimeslots: boolean,
  timeslotType: TimeslotType
): Timing[] | null {
  if (!timingFormValue?.date) {
    return null;
  }

  const dateTime: Date =
    typeof timingFormValue.date === 'string'
      ? jsDateToUtcDate(timingFormValue.date)
      : new Date(timingFormValue.date.toDateString());
  if (timingFormValue?.timeslot?.start) {
    dateTime.setHours(
      timingFormValue.timeslot.start.hours,
      timingFormValue.timeslot.start.minutes,
      timingFormValue.timeslot.start.seconds
    );
  }

  const endDateTime =
    typeof timingFormValue.date === 'string'
      ? jsDateToUtcDate(timingFormValue.date)
      : new Date(timingFormValue.date.toDateString());
  if (timingFormValue?.timeslot?.end) {
    endDateTime.setHours(
      timingFormValue.timeslot.end.hours,
      timingFormValue.timeslot.end.minutes,
      timingFormValue.timeslot.end.seconds
    );
  } else {
    endDateTime.setHours(23, 59, 59);
  }

  const result: Timing[] = [];
  if (useTimeslots && timeslotType === TimeslotType.RANGE) {
    // Specific timeslot
    result.push({
      dateTime: dateTime.toISOString(),
      endDateTime: endDateTime.toISOString(),
      type: TimingType.RANGE,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
  } else if (timingFormValue.dayparts?.parts.length > 0) {
    // Day part
    timingFormValue.dayparts.parts.forEach((part) => {
      result.push({
        dateTime: jsDateToUtcDate(dateTime).toISOString().split('T')[0],
        type: TimingType.DAY_PART,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        dayPart: part,
      });
    });
  } else {
    result.push({
      dateTime: jsDateToUtcDate(dateTime).toISOString().split('T')[0],
      type: TimingType.DATE,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      time: timingFormValue.dayparts?.time,
    });
  }
  return result;
}
