import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[nxhToggle]',
})
export class ToggleGroupDirective {
  @Input() pills = false;

  @HostBinding('class') get classNames() {
    let classNames = 'btn-group btn-group-toggle';
    if (this.pills) {
      classNames = classNames + ' btn-group-toggle--pills';
    }
    return classNames;
  }
}
