import { Component } from '@angular/core';
import { ScrollspyService } from '@nexuzhealth/shared/ui-toolkit/scrollspy';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'nxh-typography-demo',
  templateUrl: './typography-demo.component.html',
  styleUrls: ['./typography-demo.component.scss'],
})
export class TypographyDemoComponent {
  showExampleBlock1 = true;
  block1Code = `
  <element class="hero-title">Hero title</element>
  <element class="page-title">Page title</element>
  <element class="sub-title">Sub title</element>
  <element class="modal-title">Modal title</element>
  <element class="card-title">Card title</element>
  <element class="form-subtitle">Form subtitle</element>
  <element class="heavy-text">Heavy text</element>
  <element class="small-title">Small title</element>
  <element class="label-on-data">Label on data</element>
  <element class="body-text">Data / Body</element>
  <element class="label-on-form">Label on form</element>
  <element class="mask-on-form">Mask on form</element>
  <element class="text-link">Text link</element>
  `;

  selected$ = new BehaviorSubject('block1');

  constructor(private scrollspyService: ScrollspyService) {}

  scrollTo(id: string) {
    this.selected$.next(id);
    this.scrollspyService.scrollTo(id, { emitEvent: false });
  }

  scrolledInView(id: string) {
    this.selected$.next(id);
  }
}
