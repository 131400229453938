import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { DAY } from '@nexuzhealth/shared/domain';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RecurrencesFormService } from '../recurrences-form.service';

@Component({
  selector: 'nxh-recurrence-tab-month',
  templateUrl: './recurrence-tab-month.component.html',
  styleUrls: ['./recurrence-tab-month.component.scss'],
})
export class RecurrenceTabMonthComponent implements OnInit, OnDestroy {
  @Input() parent!: UntypedFormGroup;

  private destroy$$ = new Subject<void>();
  dayOptions = Object.values(DAY);
  daysOfMonth = Array(31)
    .fill(null)
    .map((day, index) => ({ value: String(index + 1), label: (index + 1 + '').padStart(2, '0') }));

  get validateDayPartOrTime() {
    return this.recurrencesFormService.getValidateDayPartOrTime();
  }

  get useDayparts() {
    return this.recurrencesFormService.getUseDayparts();
  }

  constructor(private recurrencesFormService: RecurrencesFormService) {}

  get dayparts() {
    return this.recurrencesFormService.getDayParts();
  }

  get days(): UntypedFormArray {
    return this.parent.get('month')?.get('days') as UntypedFormArray;
  }

  get dayGroup() {
    return this.days.controls[0] as UntypedFormGroup;
  }

  get useTimeslots() {
    return this.recurrencesFormService.getUseTimeslots();
  }

  get useDayPartAllDay() {
    return this.recurrencesFormService.getUseDayPartAllDay();
  }

  ngOnInit(): void {
    this.dayGroup
      .get('_selectNthDayOrWeekday')
      ?.valueChanges.pipe(takeUntil(this.destroy$$))
      .subscribe((value) => {
        if (value === 'nthDay') {
          this.dayGroup.get('nthDay')?.enable();
          this.dayGroup.get('weekday')?.disable();
          this.dayGroup.get('dayparts')?.disable();
        } else if (value === 'weekday') {
          this.dayGroup.get('nthDay')?.disable();
          this.dayGroup.get('weekday')?.enable();
          this.dayGroup.get('dayparts')?.disable();
        } else {
          this.dayGroup.get('nthDay')?.disable();
          this.dayGroup.get('weekday')?.disable();
          this.dayGroup.get('dayparts')?.enable();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$$.next();
    this.destroy$$.complete();
  }
}
