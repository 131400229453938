import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { CompactFormControlDirectiveModule } from '@nexuzhealth/shared/ui-toolkit/compact-form-control';
import { EmailAddressComponent } from './email-address.component';

@NgModule({
  imports: [CompactFormControlDirectiveModule, FormsModule],
  exports: [EmailAddressComponent],
  declarations: [EmailAddressComponent],
})
export class EmailAddressModule {}
