<div class="btn-group" role="group" nxhToggle [pills]="pills">
  <ng-container *ngFor="let option of options; let i = index">
    <input
      type="radio"
      class="btn-check"
      [formControl]="formControl"
      [value]="option.value"
      [attr.checked]="option.value === formControl.value"
      nxhDataTest="{{ testPrefix }}-{{ i }}"
      [disabled]="option.disabled || formControl.disabled"
      id="{{ testPrefix }}-{{ option.value }}-{{ i + randomId }}"
      [name]="randomId"
      autocomplete="off"
    />
    <label
      class="btn btn-primary"
      nxhToggleOption
      [showCheckmark]="false"
      [class.active]="option.value === formControl.value"
      [class.disabled]="option.disabled || formControl.disabled"
      [for]="option.disabled ? '' : testPrefix + '-' + option.value + '-' + (i + randomId)"
    >
      <ng-container *ngIf="optionDirective?.template; else noTemplate">
        <ng-container
          *ngTemplateOutlet="
            optionDirective.template;
            context: { $implicit: option, active: option.value === formControl.value }
          "
        ></ng-container>
      </ng-container>
      <ng-template #noTemplate>
        <span>{{ option.label | i18next }}</span>
      </ng-template>
    </label>
  </ng-container>
</div>
