import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  LOCALE_ID,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { guid } from '@datorama/akita';
import { FullCalendarComponent } from '@fullcalendar/angular';
import resourceTimegridPlugin from '@fullcalendar/resource-timegrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import { Range } from '@nexuzhealth/shared/domain';
import { I18NextPipe } from 'angular-i18next';

import {
  Calendar,
  DayHeaderContentArg,
  EventClickArg,
  EventContentArg,
  EventInput,
  NowIndicatorContentArg,
} from '@fullcalendar/core';
import { ResourceLabelContentArg } from '@fullcalendar/resource';
import { DateService } from '@nexuzhealth/shared/util';
import { FULLCALENDAR_LICENSE_KEY_TOKEN } from '../fullcalendar-license-key-token';
import { getCalendarOptions, LicensedCalendarOptions } from '../calendar-utils';
import {
  CalendarConfig,
  CalendarResource,
  DayHeaderContent,
  TimeGridCalendarEvent,
  TimeGridViewType,
} from '../calendar.model';
import { TemplateHelperService } from '../template-helper.service';

@Component({
  selector: 'nxh-calendar-time-grid',
  templateUrl: './calendar-time-grid.component.html',
  styleUrls: ['./calendar-time-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TemplateHelperService],
})
export class CalendarTimeGridComponent<T> implements OnChanges {
  @HostBinding('class.card') cardClass = true;
  @Input() viewType: TimeGridViewType;
  @Input() range: Range;
  @Input() events: TimeGridCalendarEvent[] | T[];
  @Input() eventContentTemplate: TemplateRef<{ $implicit: TimeGridCalendarEvent }>;
  @Input() resourceLabelContentTemplate: TemplateRef<{ $implicit: CalendarResource }>;
  @Input() resources: CalendarResource[];
  @Input() config: CalendarConfig;

  /**
   * Function that transforms an item present in the list of events into a TimeGridCalendarEvent. Use this if the given
   * array of events aren't already TimeGridCalendarEvents.
   */
  @Input() eventDataTransform?: (eventInput: T) => TimeGridCalendarEvent;

  @Output() selectEvent = new EventEmitter<{ srcElement: HTMLElement; event: TimeGridCalendarEvent }>();

  @ViewChild('eventTemplate', { static: true }) eventTemplate!: TemplateRef<{ $implicit: TimeGridCalendarEvent }>;
  @ViewChild('dayHeaderTemplate', { static: true }) dayHeaderTemplate!: TemplateRef<{
    $implicit: DayHeaderContent;
  }>;
  @ViewChild(FullCalendarComponent, {
    static: true,
    read: FullCalendarComponent,
  })
  fullCalendarComponent!: FullCalendarComponent;

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    @Inject(FULLCALENDAR_LICENSE_KEY_TOKEN) private fullCalendarLicenseKey: string,
    private dateService: DateService,
    private i18n: I18NextPipe,
    private templateHelperService: TemplateHelperService
  ) {}

  get options(): LicensedCalendarOptions {
    return {
      ...this.getTimeGridOptions(),
      ...this.config,
      plugins: [timeGridPlugin, resourceTimegridPlugin],
      initialView: this.viewType,
      initialDate: this.range.fromDate,
      events: this.events || [],
      resources: this.resources && this.resources.length > 0 ? this.resources : [{}],
      allDaySlot: this.events?.some((e) => e.allDay),
      eventDataTransform: (eventInput: T) => this.eventDataTransformBase(eventInput),
    };
  }

  private get calendar(): Calendar {
    return this.fullCalendarComponent.getApi();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const viewTypeChange = changes['viewType'];
    if (viewTypeChange && !viewTypeChange.isFirstChange()) {
      this.calendar?.changeView(this.viewType, {
        start: this.range?.fromDate ?? new Date(),
        end: this.range?.toDate ?? new Date(),
      });
      this.calendar?.render();
    }

    const rangeChange = changes['range'];
    if (rangeChange) {
      if (!rangeChange.isFirstChange()) {
        this.calendar?.gotoDate(this.range.fromDate);
      }
    }

    const resourcesChange = changes['resources'];
    const eventsChange = changes['events'];
    if (resourcesChange || eventsChange || rangeChange) {
      this.templateHelperService.destroyViews();
    }
  }

  private eventDataTransformBase(eventInput: T): EventInput {
    const event: EventInput = this.eventDataTransform ? this.eventDataTransform(eventInput) : eventInput;
    if (!event.id) {
      event.id = guid();
    }
    if (!event.extendedProps.start) {
      event.extendedProps.start = event.start;
    }
    return event;
  }

  private getTimeGridOptions(): LicensedCalendarOptions {
    return getCalendarOptions(this.fullCalendarLicenseKey, this.locale, {
      allDaySlot: false,
      allDayText: this.i18n.transform('all-day'),
      businessHours: {
        daysOfWeek: [1, 2, 3, 4, 5],
        startTime: '08:00',
        endTime: '17:00',
      },
      eventClick: (eventClickArg: EventClickArg) =>
        this.selectEvent.next({ srcElement: eventClickArg.el, event: eventClickArg.event }),
      eventContent: (eventContentArg: EventContentArg) => ({
        domNodes: this.templateHelperService.getTemplateRootNodes(
          this.eventContentTemplate || this.eventTemplate,
          eventContentArg.event.id,
          eventContentArg.event,
          (v1, v2) => v1.id === v2.id
        ),
      }),
      initialView: TimeGridViewType.TIME_GRID_WEEK,
      nowIndicator: true,
      nowIndicatorContent: (nowIndicatorContentArg: NowIndicatorContentArg) =>
        nowIndicatorContentArg.isAxis ? this.dateService.formatDate(nowIndicatorContentArg.date, 'HH:mm') : '',
      scrollTime: '07:55:00',
      slotDuration: '00:15',
      slotEventOverlap: false,
      slotLabelFormat: { hour: '2-digit', minute: '2-digit', hour12: false },
      slotLabelInterval: '00:15',
      views: {
        timeGridDay: {
          dayHeaderContent: { html: '' },
        },
        resourceTimeGridDay: {
          resourceLabelContent: this.resourceLabelContentTemplate
            ? (resourceLabelContentArg: ResourceLabelContentArg) => ({
                domNodes: this.templateHelperService.getTemplateRootNodes(
                  this.resourceLabelContentTemplate,
                  resourceLabelContentArg.resource.id,
                  resourceLabelContentArg.resource,
                  (v1, v2) => v1.id === v2.id
                ),
              })
            : undefined,
        },
        timeGridWeek: {
          dayHeaderContent: (dayHeaderContentArg: DayHeaderContentArg) => ({
            domNodes: this.templateHelperService.getTemplateRootNodes(
              this.dayHeaderTemplate,
              dayHeaderContentArg.date.toDateString(),
              {
                date: dayHeaderContentArg.date,
                isToday: dayHeaderContentArg.isToday,
              },
              (v1, v2) => v1.date.toDateString() === v2.date.toDateString()
            ),
          }),
        },
      },
    });
  }
}
