import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Practitioner } from '@nexuzhealth/shared/domain';

import { PractitionerState, PractitionerStateKey, PractitionerStore } from './practitioner.store';

@Injectable({
  providedIn: 'root',
})
export class PractitionerQuery extends QueryEntity<PractitionerState, Practitioner, PractitionerStateKey> {
  constructor(protected store: PractitionerStore) {
    super(store);
  }
}
