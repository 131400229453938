import {
  ClockTime,
  DAY,
  RecurrenceTiming,
  RecurrenceTimingTimeslot,
  ResourceName,
  Timeslot,
  TimeslotType,
} from '@nexuzhealth/shared/domain';

export interface FormValue {
  name: ResourceName;
  recurrences: RecurrenceFormValue;
  timings: TimingFormValue[];
}

export enum RecurrenceTabType {
  daily = 'daily',
  weekly = 'weekly',
  monthly = 'monthly',
  manual = 'manual',
}

type RecurrenceFormValue = DayRecurrenceFormValue | WeekRecurrenceFormValue | MonthRecurrenceFormValue;

export interface DayRecurrenceFormValue {
  period: number;
  day: { timings?: RecurrenceTimingTimeslot[]; dayparts?: DaypartsFormValue };
}

interface DaypartsFormValue {
  allDayOrTime: boolean;
  time: string;
  parts: string[];
}

export interface WeekRecurrenceFormValue {
  period: number;
  week: {
    _selectFrequencyOrWeekday: 'frequency' | 'weekday';
    frequency?: number;
    days?: WeekDayRecurrenceFormValue[];
    timings?: RecurrenceTiming[];
    sameTimingsForAllDays?: boolean;
    dayparts?: DaypartsFormValue;
  };
}

export interface WeekDayRecurrenceFormValue {
  weekday: DAY;
  timings?: RecurrenceTiming[];
  dayparts?: DaypartsFormValue;
}

export interface MonthRecurrenceFormValue {
  period: number;
  month: {
    days: {
      _selectNthDayOrWeekday: 'nthDay' | 'weekday';
      nthDay?: {
        _period: number;
        nthDay: number;
      };
      weekday?: {
        _period: number;
        weekday: DAY;
        nth: number;
      };
      dayparts?: {
        _period: number;
        days: string[];
      };
      timings?: { timeslot: Timeslot }[];
    }[];
    dayparts?: DaypartsFormValue;
  };
}

export interface TimingFormValue {
  date: Date | string;
  timeslot: {
    start: ClockTime;
    end: ClockTime;
  };
  dayparts?: DaypartsFormValue;
  type: TimeslotType;
}

export const DEFAULT_RECURRENCE_VALUES = {
  DAY: {
    recurrences: [{ period: 1, day: { timings: [] } }],
  },
  WEEK: {
    recurrences: [{ period: 1, week: { days: [] } }],
  },
  MONTH: {
    recurrences: [{ period: 1, month: { days: [] } }],
  },
  MANUAL: {
    timings: [],
  },
} as const;
