import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { SharedTechFeatureE2eModule } from '@nexuzhealth/shared/tech/feature-e2e';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedUtilI18nModule } from '@nexuzhealth/shared/util';
import { ValidationModule } from '@nexuzhealth/shared/ui-toolkit/validation';
import { PartialDateInputComponent } from './partial-date-input.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    SharedTechFeatureE2eModule,
    ValidationModule,
    SharedUtilI18nModule,
  ],
  exports: [PartialDateInputComponent],
  declarations: [PartialDateInputComponent],
})
export class PartialDateInputModule {}
