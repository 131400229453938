import { Component } from '@angular/core';
import { NavItem } from '@nexuzhealth/shared/domain';

@Component({
  selector: 'nxh-tabnav-demo',
  templateUrl: './tabnav-demo.component.html',
  styleUrls: ['./tabnav-demo.component.scss'],
})
export class TabnavDemoComponent {
  tabNavComponentCodeSnippet = `
  <div class="tabnav-container">
    <nxh-tabnav
      [topLevel]="true" // top level only
      [navItems]="navItems"
      [btnName]="'New Patient'"
      (btnActionEmitter)="showAlertMessage()"
    ></nxh-tabnav>
  </div>
  <router-outlet></router-outlet>`;
  routerModuleConfig = `
  {
    path: 'tab-nav',
    component: TabnavDemoComponent,
    children: [
      {
        path: 'tab1',
        component: Tab1Component
      },
      {
        path: 'tab2',
        component: Tab2Component
      },
      {
        path: 'tab3',
        component: Tab3Component
      }
    ]
  }`;
  navItems: NavItem[] = [
    { routerLink: 'tab1', label: 'tab1' },
    { routerLink: 'tab2', label: 'tab2' },
    { routerLink: 'tab3', label: 'tab3' },
  ];
  constructor() {}

  showAlertMessage() {
    alert('Hello World!');
  }
}
