import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedUtilI18nModule } from '@nexuzhealth/shared/util';
import { SharedSettingsFeatureFlagsUiModule } from '@nexuzhealth/shared/settings/feature-flags/ui';

import { ButtonModule } from '@nexuzhealth/shared/ui-toolkit/button';
import { IconsModule } from '@nexuzhealth/shared/ui-toolkit/icons';
import { TabnavComponent } from './tabnav.component';
import { TabComponent } from './tab/tab.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedUtilI18nModule,
    SharedSettingsFeatureFlagsUiModule,
    ButtonModule,
    IconsModule,
  ],
  exports: [TabnavComponent, TabComponent],
  declarations: [TabnavComponent, TabComponent],
})
export class TabnavModule {}
