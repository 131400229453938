import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedUtilI18nModule } from '@nexuzhealth/shared/util';
import { Nl2brPipeModule } from '@nexuzhealth/shared/ui-toolkit/pipes/nl2br';
import { A11yModule } from '@angular/cdk/a11y';
import { IconsModule } from '@nexuzhealth/shared/ui-toolkit/icons';
import { ButtonModule } from '@nexuzhealth/shared/ui-toolkit/button';
import { ConfirmDirective, ConfirmInfoDirective, ConfirmWarningDirective } from './confirm.directive';
import { ConfirmComponent } from './confirm.component';

@NgModule({
  imports: [CommonModule, SharedUtilI18nModule, IconsModule, Nl2brPipeModule, A11yModule, ButtonModule],
  declarations: [ConfirmComponent, ConfirmDirective, ConfirmInfoDirective, ConfirmWarningDirective],
  exports: [ConfirmComponent, ConfirmDirective, ConfirmInfoDirective, ConfirmWarningDirective],
})
export class ConfirmModule {}
