import { Component } from '@angular/core';
import { ScrollspyService } from '@nexuzhealth/shared/ui-toolkit/scrollspy';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'nxh-page-layout',
  templateUrl: './page-layout.component.html',
  styleUrls: ['./page-layout.component.scss'],
})
export class PageLayoutComponent {
  showExampleBlock1 = true;
  block1Code = `
  <div class="nh-page-wrapper">
    // tabnav-container here
    <div class="nh-page">
      <div class="nh-page__header">
        <div class="nh-page__header__title-container">
          <div class="nh-page__header__title--left">
            <nxh-back-button routerlink=""></nxh-back-button>
            <h1 class="page-title">Page title here</h1>
          </div>
          <div class="nh-page__header__title__controls">
            // your controls here
          </div>
        </div>
        <div class="nh-page__header--bottom">
          // navtab menu (below title) here
        </div>
      </div>
      <div class="nh-page__content">
        <div class="nh-page__content--top">
          // filters here
        </div>
        <div class="nh-page__content--body">
          // cards, tables, forms, ... here
        </div>
        <div class="nh-page__content--bottom">
          // footer here
        </div>
      </div>
    </div>
  </div>
  `;

  showExampleBlock2 = true;
  block2Code = `
  <div class="nh-page-wrapper">
    // tabnav-container here
    <div class="nh-page">
      <div class="nh-page__header">
        <div class="nh-page__header__title-container">
          <div class="nh-page__header__title--left">
            <nxh-back-button routerlink=""></nxh-back-button>
            <h1 class="page-title">Page title here</h1>
          </div>
          <div class="nh-page__header__title__controls">
            // your controls here
          </div>
        </div>
        <div class="nh-page__header--bottom">
          // navtab menu (below title) here
        </div>
      </div>
      <div class="nh-page__content nh-page__content--limited">
        <div class="nh-page__content--top">
          // filters here
        </div>
        <div class="nh-page__content--body">
          // cards, tables, forms, ... here
        </div>
        <div class="nh-page__content--bottom">
          // footer here
        </div>
      </div>
    </div>
  </div>
  `;

  showExampleBlock3 = true;
  block3Code = `
  <div class="nh-page-wrapper">
    // tabnav-container here
    <div class="nh-page">
      <div class="nh-page__header nh-page__header--limited">
        <div class="nh-page__header__title-container">
          <div class="nh-page__header__title--left">
            <nxh-back-button routerlink=""></nxh-back-button>
            <h1 class="page-title">Page title here</h1>
          </div>
          <div class="nh-page__header__title__controls">
            // your controls here
          </div>
        </div>
        <div class="nh-page__header--bottom">
          // navtab menu (below title) here
        </div>
      </div>
      <div class="nh-page__content nh-page__content--limited">
        <div class="nh-page__content--top">
          // filters here
        </div>
        <div class="nh-page__content--body">
          // cards, tables, forms, ... here
        </div>
        <div class="nh-page__content--bottom">
          // footer here
        </div>
      </div>
    </div>
  </div>
  `;

  showExampleBlock4 = true;
  block4Code = `
  <div class="nh-page-wrapper">
    // tabnav-container here
    <div class="nh-page">
      <div class="nh-page__header nh-page__header--limited nh-page__header--centered">
        <div class="nh-page__header__title-container">
          <div class="nh-page__header__title--left">
            <nxh-back-button routerlink=""></nxh-back-button>
            <h1 class="page-title">Page title here</h1>
          </div>
          <div class="nh-page__header__title__controls">
            // your controls here
          </div>
        </div>
        <div class="nh-page__header--bottom">
          // navtab menu (below title) here
        </div>
      </div>
      <div class="nh-page__content nh-page__content--limited nh-page__content--centered">
        <div class="nh-page__content--top">
          // filters here
        </div>
        <div class="nh-page__content--body">
          // cards, tables, forms, ... here
        </div>
        <div class="nh-page__content--bottom">
          // footer here
        </div>
      </div>
    </div>
  </div>
  `;

  selected$ = new BehaviorSubject('block1');

  constructor(private scrollspyService: ScrollspyService) {}

  scrollTo(id) {
    this.selected$.next(id);
    this.scrollspyService.scrollTo(id, { emitEvent: false });
  }

  scrolledInView(id) {
    this.selected$.next(id);
  }
}
