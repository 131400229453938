import { NgModule } from '@angular/core';

import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { SharedUtilI18nModule } from '@nexuzhealth/shared/util';

import { IconsModule } from '@nexuzhealth/shared/ui-toolkit/icons';
import { ButtonModule } from '@nexuzhealth/shared/ui-toolkit/button';
import { HotkeysComponent } from './hotkeys.component';

@NgModule({
  imports: [CommonModule, NgbModalModule, IconsModule, SharedUtilI18nModule, ButtonModule],
  exports: [HotkeysComponent],
  declarations: [HotkeysComponent],
})
export class HotkeysModule {}
