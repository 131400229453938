import { Component, HostBinding, inject, Input } from '@angular/core';
import { DataListComponent } from './data-list.component';

@Component({
  selector: 'nxh-dl-row',
  template: `
    <ng-content select="nxh-dl-key" />
    <nxh-dl-key *ngIf="key">{{ key }}</nxh-dl-key>

    <div class="data-list-val-container" [class.data-list--key-size-S]="keySizeS" *ngIf="!noVal">
      <nxh-dl-val *ngIf="valueByInput">{{ val }}</nxh-dl-val>
      <ng-content select="nxh-dl-val" />
    </div>
  `,
  styles: [
    `
      :host.data-list__row {
        /*overwriting datalist.scss data-list */
        gap: 0;
      }

      .data-list-val-container {
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: 0.5rem;
      }

      .data-list-val-container.data-list--key-size-S {
        flex: 3;
      }
    `,
  ],
})
export class DataListRowComponent {
  @Input() vertical = false;
  @Input() key: string | number;
  @Input() noVal = false;
  protected valueByInput = false;
  private parent = inject(DataListComponent);

  @HostBinding('class.data-list__block') get block() {
    return this.vertical;
  }

  @HostBinding('class.data-list__row') get row() {
    return !this.vertical;
  }

  get keySizeS() {
    return this.parent.keySize === 'S';
  }

  private _val: string | number | null | undefined;

  get val() {
    return this._val;
  }

  @Input() set val(val: string | number | null | undefined) {
    this.valueByInput = true;
    this._val = val;
  }
}
