import { Component } from '@angular/core';
import { Range } from '@nexuzhealth/shared/domain';
import {
  CalendarResource,
  FULLCALENDAR_LICENSE_KEY_TOKEN,
  ResourceTimelineCalendarEvent,
  TimeGridCalendarEvent,
  TimeGridViewType,
} from '@nexuzhealth/shared/ui-toolkit/calendar';
import { add } from 'date-fns/add';

@Component({
  selector: 'nxh-calendar',
  templateUrl: './calendar-demo.component.html',
  styleUrls: ['./calendar-demo.component.scss'],
  providers: [{ provide: FULLCALENDAR_LICENSE_KEY_TOKEN, useValue: 'test' }],
})
export class CalendarDemoComponent {
  selectedCalendar = 'time-grid';

  viewType: TimeGridViewType = TimeGridViewType.TIME_GRID_WEEK;
  range: Range = { fromDate: new Date(), toDate: add(new Date(), { weeks: 1 }) };
  gridEvents: TimeGridCalendarEvent[] = [
    {
      start: new Date(),
      end: add(new Date(), { hours: 1 }),
      allDay: false,
      extendedProps: {
        title: 'nice',
        content: 'here it is',
        duration: '5h',
        badge: 'super',
      },
    },
  ];

  gridCode = `
    <nxh-calendar-time-grid
      [viewType]="viewType"
      [range]="range"
      [events]="events"
      (selectEvent)="onSelectEvent($event)"
    ></nxh-calendar-time-grid>

    viewType: TimeGridViewType = TimeGridViewType.TIME_GRID_WEEK;
    range: Range = { fromDate: new Date(), toDate: add(new Date(), { weeks: 1 }) };
    gridEvents: TimeGridCalendarEvent[] = [
      {
        start: new Date(),
        end: add(new Date(), { hours: 1 }),
        allDay: false,
        extendedProps: {
          title: 'nice',
          content: 'here it is',
          duration: '5h',
          badge: 'super',
        },
      },
    ];

    onSelectEvent($event: { srcElemement: HTMLElement; event: TimeGridCalendarEvent }) {
      console.log("$event", $event);
    }
  `;

  timelineEvents: ResourceTimelineCalendarEvent[] = [
    {
      start: new Date(),
      end: add(new Date(), { hours: 1 }),
      resourceId: '1',
      extendedProps: {
        start: new Date(),
        end: add(new Date(), { hours: 1 }),
      },
    },
  ];
  resources: CalendarResource[] = [
    {
      id: '1',
      title: 'hallo',
    },
  ];
  timelineCode = `

    <nxh-calendar-resource-timeline
      [range]="range"
      [resources]="resources"
      [resourceHeader]="'timeline'"
      [events]="timelineEvents"
    ></nxh-calendar-resource-timeline>

    timelineEvents: ResourceCalendarEvent[] = [
      {
        start: new Date(),
        end: add(new Date(), { hours: 1 }),
        resourceId: '1',
        extendedProps: {
          start: new Date(),
          end: add(new Date(), { hours: 1 }),
        },
      },
    ];
    resources: CalendarResource[] = [
      {
        id: '1',
        title: 'hallo',
      },
    ];
  `;

  constructor() {}

  onSelectEvent($event: { srcElement: HTMLElement; event: TimeGridCalendarEvent }) {
    console.log('$event', $event);
  }
}
