import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconsModule } from '@nexuzhealth/shared/ui-toolkit/icons';
import { ButtonModule } from '@nexuzhealth/shared/ui-toolkit/button';
import { StatusMessageComponent } from './status-message/status-message.component';

@NgModule({
  imports: [CommonModule, IconsModule, ButtonModule],
  declarations: [StatusMessageComponent],
  exports: [StatusMessageComponent],
})
export class StatusMessageModule {}
