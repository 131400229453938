import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ScrollspyService } from '@nexuzhealth/shared/ui-toolkit/scrollspy';
import { BehaviorSubject } from 'rxjs';

@Component({
  templateUrl: './password-input-demo.component.html',
  styleUrls: ['./password-input-demo.component.scss'],
})
export class PasswordInputDemoComponent {
  showExampleBlock1 = true;

  block1Code = `
  <nxh-toggle-list
  formControlName="radio"
  [config]="config"
  [singleSelect]="false/true" // DEFAULT IS TRUE
  >
  </nxh-toggle-list>
  `;

  form = new UntypedFormGroup({
    password: new UntypedFormControl(),
  });
  selected$ = new BehaviorSubject('block1');

  constructor(private scrollspyService: ScrollspyService) {}

  scrollTo(id) {
    this.selected$.next(id);
    this.scrollspyService.scrollTo(id, { emitEvent: false });
  }

  scrolledInView(id) {
    this.selected$.next(id);
  }
}
